import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log("Pathname changed to:", pathname); // 디버깅용
    window.document.body.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
