import React from 'react'
import SIZE from '../../../global/Size'
import styled from 'styled-components';

const TwoTitleWithImage = ({ Image01, Image02, Title01, Title02 }) => {
    return (
      <OuterContainer>
        <ContentBlock>
          <Title>{Title01}</Title>
          <StyledImage src={Image01} />
        </ContentBlock>
        <ContentBlock>
          <Title>{Title02}</Title>
          <StyledImage src={Image02} />
        </ContentBlock>
      </OuterContainer>
    );
  }
  
  const OuterContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;

    /* 450px 이상 800px 미만 */
    @media (min-width: 450px) and (max-width: 799px) {
      flex-direction: column;
    }
    
    /* 450px 미만 */
    @media (max-width: 449px) {
      flex-direction: column;
    }
  `;
  
  const ContentBlock = styled.div`
    width: 49%;
    height: auto;


    /* 450px 이상 800px 미만 */
    @media (min-width: 450px) and (max-width: 799px) {
      width: 100%;
    }
    
    /* 450px 미만 */
    @media (max-width: 449px) {
      width: 100%;
    }
  `;
  
  const Title = styled.span`
    font-size: ${props => props.theme.SIZE.X05LARGE};
    font-weight: 700;
    padding-bottom: 20px;
    display: flex;
  `;
  
  const StyledImage = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;
  `;  

export default TwoTitleWithImage