import React from "react";
import backgroundImage from "../../../assets/main/Rectangle3.png";
import styled from "styled-components";

const ThirdContainer = ({ children }) => {
  return <Container $backgroundImage={backgroundImage}>{children}</Container>;
};

const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: #ccc;
  background-image: url(${(props) => props.$backgroundImage});
  background-position: center;
  background-size: cover;
  padding-top: 5%;
  padding-bottom: 9%;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    padding-bottom: 15%;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    padding-bottom: 15%;
  }
`;

export default ThirdContainer;
