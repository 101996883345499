import React from "react";
import HeaderContainer from "../../components/header/HeaderContainer";
import HeaderTitle from "../../components/header/HeaderTitle";

import IKOintro01 from "../../assets/vendorProduct/IKOintro01.png";
import IKOintro02 from "../../assets/vendorProduct/IKOintro02.png";
import IKOintro03 from "../../assets/vendorProduct/IKOintro03.png";
import IKOintro04 from "../../assets/vendorProduct/IKOintro04.png";

import IKOstory from "../../assets/vendorProduct/IKOstory.png";
import IKODesignServices from "../../assets/vendorProduct/IkoDesingServices.png";

import IKOsolutions1 from "../../assets/vendorProduct/IKOsolutions1.png";
import IKOsolutions2 from "../../assets/vendorProduct/IKOsolutions2.png";
import IKOsolutions3 from "../../assets/vendorProduct/IKOsolutions3.png";
import IKOsolutions4 from "../../assets/vendorProduct/IKOsolutions4.png";
import IKOsolutions5 from "../../assets/vendorProduct/IKOsolutions5.png";
import IKOsolutions6 from "../../assets/vendorProduct/IKOsolutions6.png";
import IKOsolutions7 from "../../assets/vendorProduct/IKOsolutions7.png";
import IKOsolutions8 from "../../assets/vendorProduct/IKOsolutions8.png";
import IKOsolutions9 from "../../assets/vendorProduct/IKOsolutions9.png";
import IKOsolutions10 from "../../assets/vendorProduct/IKOsolutions10.png";
import IKOexamples from "../../assets/vendorProduct/IKOexamples.png";

import COLOR from "../../global/Color";
import SubHeading from "../../components/inner/venderProduct/SubHeading";
import FullImage from "../../components/inner/venderProduct/FullImage";
import styled from "styled-components";
import HeaderContent from "../../components/header/HeaderContent";
import HeaderContentSmall from "../../components/header/HeaderContentSmall";
import IKOIntro from "../../components/inner/venderProduct/IKOIntro";
import IKOSolutions from "../../components/inner/venderProduct/IKOSolutions";
import IKOTable from "../../components/inner/venderProduct/IKOTable";

const Ikotek = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Ikotek</HeaderTitle>
        <HeaderContent>
          <span style={{ color: COLOR.MAGORELLEBLUE, fontWeight: 700 }}>
            ikotek
            <span
              style={{
                color: COLOR.BLACK,
                fontWeight: 300,
                display: "inline-block",
              }}
            ></span>
          </span>
          (
          <a
            style={{ textDecoration: "underline", color: "black" }}
            href="https://www.ikotek.com/"
            target="_blank"
          >
            https://www.ikotek.com
          </a>
          )은 신뢰할 수 있는 IoT ODM(Original Design Manufacturing) 전문
          파트너이고 <br />
          미국에 본사를 둔 글로벌 공급업체입니다.
        </HeaderContent>
        <HeaderContentSmall>
          We are a one-stop-shop for IoT device design, manufacturing and
          certification delivering high quality products and services <br />
          to reduce complexity, speed up time to market, eliminate risk and
          reduce costs. <br />
          <br />
          We offer proven IoT ODM expertise at unbeatable valuye and have
          designed and produced millions of IoT devices for multiple industry
          sectors. <br />
          Our experience enables us to drive innovation and efficiency at the
          same time creating a unique business <br />
          proposition for our customers.
        </HeaderContentSmall>
        <IKOIntroContainer>
          <IKOIntro
            Icon={IKOintro01}
            Content={"A US-based ODM parner with world-wide capabilities"}
          />
          <IKOIntro
            Icon={IKOintro02}
            Content={"A US-based ODM parner with world-wide capabilities"}
          />
          <IKOIntro
            Icon={IKOintro03}
            Content={"A US-based ODM parner with world-wide capabilities"}
          />
          <IKOIntro
            Icon={IKOintro04}
            Content={"A US-based ODM parner with world-wide capabilities"}
          />
        </IKOIntroContainer>
        <br />
        <br />
        <SubHeading>The Ikotek Story</SubHeading>
        <HeaderContentSmall>
          Deep roots growing from the IoT industry
        </HeaderContentSmall>
        <ImageBoxContainer>
          <FullImage Item={IKOstory} />
        </ImageBoxContainer>
        <HeaderContentSmall>
          Our ODM team was established in 2019, now having more than 400
          experienced R&D employees and the senior executives with the average
          of more than 20 years of management experience in IoT organizations.
        </HeaderContentSmall>
        <br />
        <br />
        <ImageBoxContainer>
          <SubHeading>Flexible Manufacturing and Design Services</SubHeading>
          <HeaderContentSmall>
            Ikotek supports EMS, JDM, and ODM models
          </HeaderContentSmall>
          <FullImage Item={IKODesignServices} />
        </ImageBoxContainer>
        <br />
        <br />
        <SubHeading>Ikotek Solutions</SubHeading>
        <HeaderContentSmall>
          Our flexible services take you from concept to mass production and
          encompass:
        </HeaderContentSmall>
        <IKOSolutions
          Item={[
            IKOsolutions1,
            IKOsolutions2,
            IKOsolutions3,
            IKOsolutions4,
            IKOsolutions5,
            IKOsolutions6,
            IKOsolutions7,
            IKOsolutions8,
            IKOsolutions9,
            IKOsolutions10,
          ]}
        />
        <br />
        <br />

        <ImageBoxContainer>
          <SubHeading>Current Product Focuses and Examples</SubHeading>
          <FullImage Item={IKOexamples} />
        </ImageBoxContainer>

        <br />
        <br />

        <SubHeading>The Ikotek Advantage</SubHeading>
        <IKOTable
          Title={"R&D"}
          Content={
            "Strong R&D team with rich experience on wireless products, covering 2G/3G/4G/5G/GNSS based products, leveraging development tools and test equipment of Quectel. Strategy partnership with Qualcomm to provide state-of-art product and solutions"
          }
          bgColor={"#86A6C1"}
        />
        <IKOTable
          Title={"Supply Chain"}
          Content={
            "End-to-end supply chain services, good cost and lead time by leveraging strong supply chain network of Quectel’s module business."
          }
          bgColor={"#4A7090"}
        />
        <IKOTable
          Title={"Production"}
          Content={
            "ODM products are produced in our own factory or other CM factories both in and outside of China."
          }
          bgColor={"#3394BA"}
        />
        <IKOTable
          Title={"Quality"}
          Content={
            "Complete quality system, rigid test process and specifications cover hardware test, software test, and reliability test."
          }
          bgColor={"#1D6295"}
        />
        <IKOTable
          Title={"Global Certification"}
          Content={
            "Global certification team and ability; designs using certified modules can further reduce certification cost and time-to-market."
          }
          bgColor={"#134263"}
        />

        <br />
        <br />
        <br />
      </HeaderContainer>
    </>
  );
};

const ImageBoxContainer = styled.div`
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 1300px) {
    display: none;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`;

const TextBoxContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding-top: 80px;
  padding-bottom: 50px;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    padding-top: 40px;
    padding-bottom: 20px;
    flex-direction: column;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    padding-top: 40px;
    padding-bottom: 20px;
    flex-direction: column;
  }
`;

const IKOIntroContainer = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    grid-template-columns: repeat(1, 1fr);
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default Ikotek;
