import React from 'react'
import SIZE from '../../global/Size'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import SubHeading from '../../components/inner/venderProduct/SubHeading'
import FullImage from '../../components/inner/venderProduct/FullImage'
import ImageTextPanel from '../../components/inner/technologies/ImageTextPanel'

import BlackboxImage01 from '../../assets/technologies/blackboxImage01.png'
import AutomotiveImage02 from '../../assets/technologies/automotiveImage02.png'

import BlackboxTableImage01 from '../../assets/technologies/blackboxTableImage01.png';
import BlackboxTableImage02 from '../../assets/technologies/blackboxTableImage02.png';
import styled from 'styled-components'
import GoodixTable from '../../components/inner/venderProduct/GoodixTable'

const BlackBox = () => {

  const TouchPedHeaderData = ["", "FULL HD 1920 * 1080", "QHD 2688 * 1944", "UHD 3840 * 2160"];
  const TouchPedRowData = [
    ["Sensor", "OS02H10, OS02C10", "OS04C20, OS05B10", "OS08G10, OS08C10"],
    ["HDR", "Staggered HDR / DCG + VS", "Staggered HDR", "DCG / LOFIC, DAG"]
  ];

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Blackbox</HeaderTitle>
        <div style={{height: '60px'}}/>
        <HeaderContentSmall>
          Vehicle camera was used as a video recorder which can improve the driving pleasure and collect accident evidence. <br></br>
          With the development of technologies like panorama stitching, ADAS, automatic driving, vehicle camera application has been upgraded.
        </HeaderContentSmall>
        <HeaderContentSmall>
          Based on the computer vision technology, the camera can collect more information from the video and enable the control system to make decisions, <br></br>
          leads to a better experience of driving and improvement of safety.
        </HeaderContentSmall>

        <div style={{width: '100%', height: 'auto', display: 'flex', justifyContent: 'space-between', paddingBottom: 30, alignItems: 'center'}}>
          <BlackBoxImage01 src={BlackboxImage01} alt='blackboxImage'/>
            <BlackboxInfo>
              <div style={{fontSize: SIZE.MEDIUM, paddingBottom: 10}}>
                  <span style={{fontSize: SIZE.X05LARGE, fontWeight: 600, paddingBottom: 30, display: 'flex'}}>
                    What?
                  </span>
                  <HeaderContentSmall>
                    차량용 블랙박스는 Drive Recorder라고도 하며, <br></br>
                    주행중이거나 주정차 시 발생되는 모든 상황에서 영상을 <br></br>
                    녹화 저장하는 동시에 차량의 가속도 정보를 저장하는 기능의 <br></br> 
                    첨단 영상장치이다.
                  </HeaderContentSmall>
              </div>
              <div style={{fontSize: SIZE.MEDIUM}}>
                  <span style={{fontSize: SIZE.X05LARGE, fontWeight: 600, paddingBottom: 30, display: 'flex'}}>
                    Why?
                  </span>
                  <HeaderContentSmall>
                    가구당 자동차 1대 보유가 보편화 되면서 <br></br>
                    자동차 관련 사건 사고의 분쟁이 끊이지 않는다. <br></br> 
                    자동차 사고의 경우 당사자 간의 유리한 주장만을 <br></br>
                    내세우기 때문에 정확히 경위를 파악하기 어렵다. <br></br>
                    이러한 무분별한 분쟁을 막기 위하여 차량용 블랙박스가 필요하다.
                  </HeaderContentSmall>
              </div>
            </BlackboxInfo>
        </div>

        <SummaryContainer>
          <div style={{height: '60px'}}/>
        </SummaryContainer>

        <SummaryContainer>
          <SubHeading>Black Box 사용의 법제화</SubHeading>
        </SummaryContainer>
        <FullImage Item={BlackboxTableImage01}/>

        <SummaryContainer>
          <SubHeading>Omnivision ImagesSensor</SubHeading>
        </SummaryContainer>
        
        <TableBox>
          <GoodixTable HeaderData={TouchPedHeaderData} RowData={TouchPedRowData}/>
        </TableBox>
        
        <SubHeading>Eplus Solution</SubHeading>
        <ImageTextPanel 
          Image={AutomotiveImage02}
          Title={'OmniVision'}
          Link={'http://www.ovt.com'}
          Text={<>
            Driver distraction is on the rise, and advanced driver assistance systems (ADAS) are becoming mainstream. <br></br>
            Semi-autonomous and fully autonomous vehicles are not far off on the horizon. <br></br>
            Vision systems are at the heart of ADAS, powered by major technological advancements in CMOS image sensors. <br></br>
            These include camera-enabled systems for automatic emergency braking, autonomous driving, video mirror, rear view, <br></br>
            360-degree surround view and driver monitoring.
          </>}/>

      </HeaderContainer>
    </>
  )
}

const BlackBoxImage01 = styled.img`
  width: 60%;
  object-fit: contain;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`

const BlackboxInfo = styled.div`
  width: 38%;
  display: flex;
  flex-direction: column;
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    width: 100%;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    width: 100%;
  }
  `

const SummaryContainer = styled.div`
  /* 0px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }
`

const TableBox = styled.div`
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`

export default BlackBox