import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const MainInnerBtn = ({children}) => {
  return (
    <Button>
      <LinkButton to={'/company'}>
        {children}
      </LinkButton>
    </Button>
  );
}

const Button = styled.button`
  width: 10%;
  height: 5%;
  position: relative;
  top: 50%;
  left: 45%;
  display: flex;
  place-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${props => props.theme.COLOR.WHITE};
  border: 1px solid;
  background-color: ${props => props.theme.COLOR.CLEAR};
  font-size: ${props => props.theme.SIZE.MEDIUM};
  cursor: pointer;
  
  /* 1300px 이상 1500px 미만 */
  @media (min-width: 1300px) and (max-width: 1499px) {
  }

  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
    width: 15%;
    left: 43%;
  }

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    width: 20%;
    left: 40%;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    width: 30%;
    height: 10%;
    left: 35%;
  }

`;

const LinkButton = styled(Link)`
  width: 100%;
  text-decoration: none;
  text-align: center;
  place-content: center;
  color: ${props => props.theme.COLOR.WHITE};

  /* 1500px 이상 */
  @media (min-width: 1500px) {
  }

  /* 1300px 이상 1500px 미만 */
  @media (min-width: 1300px) and (max-width: 1499px) {
  }

  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
  }

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
  }

`

export default MainInnerBtn