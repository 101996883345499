import React from "react";
import HeaderContainer from "../../../components/header/HeaderContainer";
import HeaderTitle from "../../../components/header/HeaderTitle";
import LeftImageTitleBox from "../../../components/inner/venderProduct/LeftImageTitleBox";
import CaltImage01 from "../../../assets/vendorProduct/caltImage01.png";
import CaltImage02 from "../../../assets/vendorProduct/caltImage02.png";
import CaltImage03 from "../../../assets/vendorProduct/caltImage03.png";
import CaltImage04 from "../../../assets/vendorProduct/caltImage04.png";
import CaltImage05 from "../../../assets/vendorProduct/caltImage05.png";
import CaltImage06 from "../../../assets/vendorProduct/caltImage06.png";
import CaltImage07 from "../../../assets/vendorProduct/caltImage07.png";

import CaltTableImage01 from "../../../assets/vendorProduct/catlTableImage01.png";
import CaltTableImage02 from "../../../assets/vendorProduct/catlTableImage02.png";
import CaltTableImage03 from "../../../assets/vendorProduct/catlTableImage03.png";

import SIZE from "../../../global/Size";
import COLOR from "../../../global/Color";
import SubHeading from "../../../components/inner/venderProduct/SubHeading";
import FullImage from "../../../components/inner/venderProduct/FullImage";
import HeaderContentSmall from "../../../components/header/HeaderContentSmall";
import TwoImageBox from "../../../components/inner/venderProduct/TwoImageBox";
import styled from "styled-components";
import HeaderContent from "../../../components/header/HeaderContent";

const Catl = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderTitle>CATL</HeaderTitle>
        <HeaderContent>
          <span style={{ color: COLOR.MAGORELLEBLUE, fontWeight: 700 }}>
            CATL
            <span
              style={{
                color: COLOR.BLACK,
                fontWeight: 300,
                display: "inline-block",
              }}
            ></span>
          </span>
          (
          <a
            style={{ textDecoration: "underline", color: "black" }}
            href="https://www.catl.com/kr"
            target="_blank"
          >
            https://www.catl.com
          </a>
          )은 혁신적인 에너지 신기술 분야의 글로벌 리더로서 전 세계 새로운
          에너지 애플리케이션을 위한 우수한 솔루션과 서비스 제공을 다짐합니다.{" "}
          <br />
        </HeaderContent>
        <LeftImageTitleBox
          Image={CaltImage01}
          Title={
            <>
              3.2V 100Ah CATL LiFePO4 Battery cell with <br></br> screws for
              easy connection
            </>
          }
        >
          <SubContentBox>
            <div style={{ width: "30%" }}>
              <p style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Module
              </p>
              <span>001CB270</span>
            </div>

            <div style={{ width: "30%" }}>
              <p style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Nominal Voltage
              </p>
              <span>3.2V</span>
            </div>

            <div style={{ width: "30%" }}>
              <p style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Nominal Capacity
              </p>
              <span>100Ah</span>
            </div>
          </SubContentBox>

          <SubContentBox>
            <div style={{ width: "30%" }}>
              <p style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Internal Resistance
              </p>
              <span>0.39±0.05mΩ</span>
            </div>

            <div style={{ width: "70%" }}>
              <p style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Dimension
              </p>
              <span style={{ display: "inline-block" }}>
                200.33*172.2*33.22mm±0.5mm
              </span>
            </div>
          </SubContentBox>

          <SubContentBox>
            <div style={{ width: "30%" }}>
              <p style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Weight
              </p>
              <span>2.27±0.30Kg</span>
            </div>
          </SubContentBox>
        </LeftImageTitleBox>

        <ImageBoxContainer>
          <SubHeading>Parameters</SubHeading>
        </ImageBoxContainer>
        <FullImage Item={CaltTableImage01} />

        <ImageBoxContainer>
          <SubHeading>Details</SubHeading>
          <p style={{ fontSize: SIZE.MEDIUM2, lineHeight: 1.7 }}>
            Now we provide the specially customized 100Ah 3.2V CATL LiFePO4
            Battery cell with screws for easy connection. <br></br>
            It has long cycle life, high energy density and a wide operating
            temperature range.
          </p>
        </ImageBoxContainer>

        <FullImage Item={CaltImage02} />

        <SubHeading>CATL New LiFePO4 Battery 280Ah Prismatic Cell</SubHeading>
        <FullImage Item={CaltImage03} />
        <HeaderContentSmall>
          CATL (Contemporary Amperex Technology Co. Limited) is a Chinese
          battery manufacturer that produces lithium-ion batteries for electric
          vehicles and <br></br>
          energy storage systems. The CATL 280Ah battery is a high-capacity
          lithium-ion battery that is designed for use in energy storage
          systems.
        </HeaderContentSmall>
        <HeaderContentSmall>
          CATL 3.2V 280Ah lithium iron phosphate LiFePO4 battery is a new model
          with an aluminum case produced by CATL, a leading lithium battery
          supplier from China; <br></br>
          this battery cell has a super long cycle life of more than 12000times,
          have a stable performance used for ESS(energy storage system).{" "}
          <br></br>
          All the cells we provide are brand new grade A with test report and
          all kinds of certifications.
        </HeaderContentSmall>

        <SubHeading>CATL 280Ah Battery cell performance</SubHeading>
        <HeaderContentSmall>
          1. This model is 280Ah model, With intact QR code and brand new grade
          A cell. <br></br>
          2. The 280ah cell's terminal has been welded with bolts for easy
          connection, and we provide free busbars. <br></br>
          3. The cell has a maximum cycle life of 12000times. <br></br>
          4. All batteries are tested before shipment, and the test report is
          provided
        </HeaderContentSmall>

        <SubHeading>Specifications</SubHeading>
        <FullImage Item={CaltTableImage02} />
        <FullImage Item={CaltImage04} />

        <LeftImageTitleBox
          Image={CaltImage05}
          Title={
            <>
              CATL 3.2V 306Ah LiFePO4 Prismatic Battery Cell <br></br>
              With More than 4000 cycles
            </>
          }
        >
          <SubContentBox>
            <div style={{ width: "30%" }}>
              <p style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Capacity
              </p>
              <span>306Ah</span>
            </div>

            <div style={{ width: "30%" }}>
              <p style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Voltage nominal
              </p>
              <span>3.2V</span>
            </div>

            <div style={{ width: "40%" }}>
              <p style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Chemistry
              </p>
              <span>LiFePO4 (lithium ion phosphate)</span>
            </div>
          </SubContentBox>

          <SubContentBox>
            <div style={{ width: "30%" }}>
              <p style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Operating Voltage
              </p>
              <span>2.5V~3.65V</span>
            </div>

            <div style={{ width: "30%" }}>
              <p style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Internal Resistance
              </p>
              <span>0.18±0.05mΩ</span>
            </div>

            <div style={{ width: "40%" }}>
              <p style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Dimensions
              </p>
              <span>207.2*71.65*174mm</span>
            </div>
          </SubContentBox>

          <SubContentBox>
            <div style={{ width: "30%" }}>
              <p style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Weight
              </p>
              <span>≤5.51Kg</span>
            </div>
          </SubContentBox>
        </LeftImageTitleBox>

        <HeaderContentSmall>
          CATL 306Ah LFP LiFePO4 battery cells can meet the needs of high-energy
          density and long distance travel, as well as safety and reliability.{" "}
          <br></br>
          It has been widely applied to battery electric vehicles(BEV), plug-in
          hybrid electric vehicles(PHEV), hybrid electric vehicles and energy
          storage systems.
        </HeaderContentSmall>

        <SubHeading>Details</SubHeading>
        <TwoImageBox Image01={CaltImage06} Image02={CaltImage07} />

        <SubHeading>CATL 306Ah Datasheet</SubHeading>
        <FullImage Item={CaltTableImage03} />

        <HeaderContentSmall>
          1. This item is 306Ah item, 302ah battery cell's upgraded version; the
          QR code is intact, grade A+, brand new.(the actual capacity is around
          310ah) <br></br>
          2. The price comes with busbars and bolts, a battery will come with a
          busbar and bolt set (for example, if you buy a 4-piece battery,{" "}
          <br></br>
          we will send you 4-piece cells with 4-piece busbars and nuts and bolts
          8 pieces studs) If you need more, please contact us online Thank you!{" "}
          <br></br>
          3. Bus bars size: L90 * W20 * 2mm (nickel plated copper) Screw size:
          M6 <br></br>
          4. We will test all battery voltage, internal resistance and capacity
          before delivery.
        </HeaderContentSmall>
      </HeaderContainer>
    </>
  );
};

const ImageBoxContainer = styled.div`
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 1300px) {
    display: none;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`;

const SubContentBox = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding-bottom: 30px;

  @media (min-width: 450px) and (max-width: 700px) {
    flex-direction: column;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    flex-direction: column;
  }
`;

export default Catl;
