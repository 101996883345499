import React from 'react'
import COLOR from '../../global/Color'
import SIZE from '../../global/Size'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContent from '../../components/header/HeaderContent'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import DivideLine from '../../components/inner/venderProduct/DivideLine'
import LastHeaderContentSmall from '../../components/header/LastHeaderContentSmall'
import CenterAlignedContainer from '../../components/inner/venderProduct/CenterAlignedContainer'
import HorizontalMediaCard from '../../components/inner/venderProduct/HorizontalMediaCard'
import OmmnivisionTable from '../../components/inner/venderProduct/OmmnivisionTable'

import mobileDevice01 from '../../assets/vendorProduct/mobiledevice01.png';
import mobileDevice02 from '../../assets/vendorProduct/mobiledevice02.png';
import automotiveImage01 from '../../assets/vendorProduct/automotiveImage01.png';
import automotiveImage02 from '../../assets/vendorProduct/automotiveImage02.png';
import surveillance01 from '../../assets/vendorProduct/surveillance01.png';
import surveillance02 from '../../assets/vendorProduct/surveillance02.png';

const Omnivision = () => {

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>OmniVision</HeaderTitle>
        <HeaderContent>
        <span style={{color: COLOR.MAGORELLEBLUE, fontWeight: 700}}>OmniVision
        <span style={{color: COLOR.BLACK, fontWeight: 300, display: 'inline-block'}}>
        </span>
        </span>(<a style={{textDecoration: 'underline', color: COLOR.BLACK}} href='https://www.ovt.com' target='_blank'>https://www.ovt.com</a>)
        은 1995년 설립된 이래 다양한 산업에 걸쳐 <br></br>
          여러 응용 프로그램을 위한 발전된 디지털 이미징, 아날로그 및 디스플레이 솔루션을 <br></br>
          개발 및 공급하면서 최첨단 기술을 선도해오고 있습니다.
        </HeaderContent>

        <HeaderContentSmall>
          글로벌 팹리스 반도체 기업으로서 수상 경력에 빛나는 혁신적인 기술을 통해 자동차, 의료, 보안 및 감시, 컴퓨팅, 휴대폰 및 신흥 기술 분야에서 <br></br>
          보다 원활한 인간/기계 인터페이스 솔루션을 구현합니다.
        </HeaderContentSmall>

        <HeaderContentSmall>
          2002년에 최초의 휴대 전화 카메라 액세서리를 개발하였으며, 2006년에는 세계에서 가장 작은 NTS 카메라를 제공하였으며, <br></br>
          2013년에 PureCel CMOS 이미지 센서를 출시하여 신뢰할 수 있고 혁신적인 이미징 및 센싱 솔루션을 제공하는 것이 주요 목표입니다. 
        </HeaderContentSmall>

        <LastHeaderContentSmall>
          지속적으로 개선하고자 하는 우리의 의지를 통해 우리의 뛰어남에 대한 약속을 보여줍니다. <br/>
          품질과 서비스에 대한 헌신은 우리의 비즈니스 실천의 중요한 부분이며,가장 중요한 것은 고객 만족입니다. 
        </LastHeaderContentSmall>

      </HeaderContainer>

      <DivideLine />

      <CenterAlignedContainer>
        <span style={{fontWeight: 600, color: COLOR.MAGORELLEBLUE, fontSize: SIZE.X1LARGE, paddingBottom: 25, textAlign: 'center' }}>Mobile Device</span>
        <span style={{textAlign: 'center', lineHeight: 1.5, fontSize: SIZE.MEDIUM2, paddingBottom: 50, paddingLeft: 50, paddingRight: 50}}>
          Advanced digital imaging soluuions enable smartphone suppliers to deliver <br></br>
          professional-grade cameras into the hands of consumers
        </span>

        <HorizontalMediaCard 
          imageSrc1={mobileDevice01}
          title1={'Hight-Perfomance Camera'}
          content1={(
            <>
              Omnivision continues to progress fundamental images-sensor <br></br> 
              technology by enhancing images quality with <br></br>
              every new generation, ensuring improved user <br></br>
              experience and scene reproduction even as pixels shrink.
            </>
          )}
          imageSrc2={mobileDevice02}
          title2={'Dual-Camera Sensor Solutions'}
          content2={(
            <>
              OmniVision offers a range of sensors that enable two <br></br>
              major dual-camera features: optical zoom and low-light enhancements. <br></br>
              OmniVision's sensors are equipped with capabilities designed to <br></br>
              support dual-camera applications, such as on-chip EIS and synchronization
            </>
          )}
        />

      </CenterAlignedContainer>

      <DivideLine />

      <CenterAlignedContainer>
        <span style={{fontWeight: 600, color: COLOR.MAGORELLEBLUE, fontSize: SIZE.X1LARGE, paddingBottom: 25, textAlign: 'center' }}>
          Automotive Imaging
        </span>
        <span style={{textAlign: 'center', lineHeight: 1.5, fontSize: SIZE.MEDIUM2, paddingBottom: 50, paddingLeft: 50, paddingRight: 50}}>
          OmniVision's robust, compact and cost effective CMOS sensors are designed <br></br>
          from the start with automotive market needs in mind to deliver industry-leading image quality
        </span>

        <HorizontalMediaCard 
          imageSrc1={automotiveImage01}
          title1={'Display-Based Image Systems'}
          content1={(
            <>
              Display-based image systems such as rear-view, <br></br>
              surround-view systems and e-mirror require spectacular image quality. <br></br>
              By combining industry-leading BSI technology and companion ASICs, <br></br>
              OmniVision can enable cost-effective display-based systems.
            </>
          )}
          imageSrc2={automotiveImage02}
          title2={'Surround-View Video'}
          content2={(
            <>
              360-degree surround-view video requires a camera on <br></br>
              each side of the vehicle controlled by a cental <br></br>
              processing unit to render an aerial view. <br></br>
              OmniVision specializes in image sensors and ISPs that capture and <br></br>
              process high-quality images for a more natural scene reproduction.
            </>
          )}
        />
      </CenterAlignedContainer>

      <DivideLine />

      <CenterAlignedContainer>
        <span style={{fontWeight: 600, color: COLOR.MAGORELLEBLUE, fontSize: SIZE.X1LARGE, paddingBottom: 25, textAlign: 'center' }}>
          Surveillance
        </span>
        <span style={{textAlign: 'center', lineHeight: 1.5, fontSize: SIZE.MEDIUM2, paddingBottom: 50, paddingLeft: 50, paddingRight: 50}}>
          OmniVision offers best-in-class advanced images-system solutions for enterprise <br></br>
          and smart-home surveillance applications
        </span>

        <HorizontalMediaCard 
          imageSrc1={surveillance01}
          title1={'Commercial Surveillance System'}
          content1={(
            <>
              Commercial surveillance systems require high- <br></br>
              resolution compression technology to capture and <br></br>
              transmit crystal-clear 4K video and perform video <br></br>
              analytics. OmniVision's portfolio of processing chips and <br></br>
              high-resolution sensors is designed with these demands in mind.
            </>
          )}
          imageSrc2={surveillance02}
          title2={'Smart-Home Monitoring System'}
          content2={(
            <>
              Smart-home monitoring systems comprising battery- <br></br>
              operated wireless cameras benefit from OmniVision's <br></br>
              high-resolution, lower-power and high-sensitivity<br></br>
              image sensors. These sensors also support advanced<br></br>
              feature such as HDR, fast frame rate, facial<br></br>
              recognition RGB-IR technology.
            </>
          )}
        />
      <OmmnivisionTable />
      <div style={{height: '5vh'}} />

      </CenterAlignedContainer>
    </>
  )
}

export default Omnivision