import React from 'react'
import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import SubHeading from '../../components/inner/venderProduct/SubHeading'
import ImageTextPanel from '../../components/inner/technologies/ImageTextPanel'
import ImageTitleTextCard from '../../components/inner/technologies/ImageTitleTextCard'

import AutomotiveImage02 from '../../assets/technologies/automotiveImage02.png'
import SecurityImage01 from '../../assets/technologies/securityImage01.png'
import SecurityImage02 from '../../assets/technologies/securityImage02.png'
import SecurityImage03 from '../../assets/technologies/securityImage03.png'
import styled from 'styled-components'

const Security = () => {
  return (
    <HeaderContainer>
      <HeaderTitle>Security</HeaderTitle>

      <ImageTitleTextCardContainer>
        <ImageTitleTextCard Image={SecurityImage01} Title={'Commercial Surveillance System'} 
          Text={(<>
            Commercial surveillance systems require <br></br>
            high-resolution compression technology <br></br>
            to capture and transmit crystal-clear 4K video and <br></br>
            perform video analytics. Sensor Developer portfolio of <br></br>
            processing chips and high-resolution sensors is <br></br>
            designed with these demands in mind.
          </>)} />

        <ImageTitleTextCard Image={SecurityImage02} Title={'Smart-Home Monitoring System'} 
          Text={(<>
            Smart-home monitoring systems comprising <br></br>
            battery-operated wireless cameras benefit from <br></br> 
            OmniVision’s high-resolution, lower-power and<br></br> 
            high-sensitivity image sensors. These sensors also <br></br> 
            support advanced features such as HDR, <br></br> 
            fast frame rate, facial recognition and RGB-IR 
            technology.
          </>)} />

        <ImageTitleTextCard Image={SecurityImage03} Title={'Battery Doorbell Cam'} 
          Text={(<>
            Battery-powered doorbell cameras need to capture <br></br>
            scenes in varied lighting conditions so that both <br></br>
            foreground and background are visible, eliminating the <br></br>
            silhouette effect. PureCel® image sensors provide high <br></br>
            sensitivity and full well capacity for higher dynamic <br></br>
            range.
          </>)} />
      </ImageTitleTextCardContainer>
      
      <SubHeading>Eplus Solution</SubHeading>

      <ImageTextPanel 
          Image={AutomotiveImage02}
          Title={'OmniVision'}
          Link={'http://www.ovt.com'}
          Text={<>
            Driver distraction is on the rise, and advanced driver assistance systems (ADAS) are becoming mainstream. <br></br>
            Semi-autonomous and fully autonomous vehicles are not far off on the horizon. <br></br>
            Vision systems are at the heart of ADAS, powered by major technological advancements in CMOS image sensors. <br></br>
            These include camera-enabled systems for automatic emergency braking, autonomous driving, video mirror, rear view, <br></br>
            360-degree surround view and driver monitoring.
          </>}/>

    </HeaderContainer>
  )
}

const ImageTitleTextCardContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-top: 40px;
  padding-bottom: 40px;

    /* 0px 이상 800px 미만 */
    @media (min-width: 1px) and (max-width: 1400px) {
    flex-direction: column;
  }

`

export default Security