import React from 'react'
import DefaultBtn from '../navigationBtn/DefaultBtn'
import styled from 'styled-components';

const VenderProductBtn = ({children}) => {
  return (
    <Container>
      {children}
    </Container>
  );
}

const Container = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.COLOR.CLEAR};
  color: ${props => props.theme.COLOR.WHITE};
  border: 0;
  font-size: ${props => props.theme.SIZE.MEDIUM};

  /* 450px 이상 1000px 미만 */
  @media (min-width: 450px) and (max-width: 1000px) {
    display: none;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`;

export default VenderProductBtn