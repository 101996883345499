import React from 'react'
import COLOR from '../../../../global/Color'
import SIZE from '../../../../global/Size'
import styled from 'styled-components'

const SecondInnerTitle = ({children}) => {
  return (
    <Container>
      {children}
    </Container>
  );
}

const Container = styled.div`
  color: ${props => props.theme.COLOR.WHITE};
  font-size: ${props => props.theme.SIZE.X1LARGE};
  font-weight: 700;
  position: relative;
  padding-top: 5%;
  padding-bottom: 5%;
  left: 13%;
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    font-size: ${props => props.theme.SIZE.XLARGE};
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    font-size: ${props => props.theme.SIZE.XLARGE};
  }
`;

export default SecondInnerTitle