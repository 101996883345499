import React from 'react'
import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import SubHeading from '../../components/inner/venderProduct/SubHeading'
import FullImage from '../../components/inner/venderProduct/FullImage'
import ImageTextPanel from '../../components/inner/technologies/ImageTextPanel'

import MobileImage01 from '../../assets/technologies/mobileImage01.png'
import AutomotiveImage02 from '../../assets/technologies/automotiveImage02.png'
import MobileImage02 from '../../assets/technologies/mobileImage02.png'
import MobileImage03 from '../../assets/technologies/mobileImage03.png'



const Mobile = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Mobile</HeaderTitle>

        <div style={{height: '60px'}}></div>

        <HeaderContentSmall>
          5G smartphones will be crucial in changing mobile communication norms among users who have access to them. <br></br>
          5G is capable of transmitting data roughly 10 to 100 times faster than 4G, the current standard, and this ability of 5G will support a wide range of IoT-connected devices, <br></br>
          enhance VR and AR experiences, and enable faster and more reliable mobile video.
        </HeaderContentSmall>

        <HeaderContentSmall>
          It won't be until 2022 when 5G smartphone shipments will reach an estimated 600 million units, accounting for 31% of the global market
        </HeaderContentSmall>

        <SubHeading>Eplus Solution</SubHeading>

        <FullImage Item={MobileImage01} />

        <ImageTextPanel 
          Image={AutomotiveImage02}
          Title={'OmniVision'}
          Link={'http://www.ovt.com'}
          Text={<>
            Driver distraction is on the rise, and advanced driver assistance systems (ADAS) are becoming mainstream. <br></br>
            Semi-autonomous and fully autonomous vehicles are not far off on the horizon. <br></br>
            Vision systems are at the heart of ADAS, powered by major technological advancements in CMOS image sensors. <br></br>
            These include camera-enabled systems for automatic emergency braking, autonomous driving, video mirror, rear view, <br></br>
            360-degree surround view and driver monitoring.
          </>}/>

        <ImageTextPanel 
          Image={MobileImage02}
          Title={'Goodix'}
          Link={'http://www.goodix.com'}
          Text={<>
            Fresco Logic is the leading developer in providing advanced I/O silicon that deliver highly efficient connectivity such as USB 3.1, <br></br>
            F-One Aggregation, USB Type-C, USB Video, and USB Power Delivery for next-generation consumer electronics, mobile, <br></br>
            and industrial IoT. Today, Over One Billion USB3.0/1 ports in the world has adopted Fresco Logic technology.
          </>}/>

        <ImageTextPanel 
          Image={MobileImage03}
          Title={'Visualon'}
          Link={'http://www.visualon.com'}
          Text={<>
            Fresco Logic is the leading developer in providing advanced I/O silicon that deliver highly efficient connectivity such as USB 3.1, <br></br>
            F-One Aggregation, USB Type-C, USB Video, and USB Power Delivery for next-generation consumer electronics, mobile, <br></br>
            and industrial IoT. Today, Over One Billion USB3.0/1 ports in the world has adopted Fresco Logic technology.
          </>}/>

      </HeaderContainer>

    </>
  )
}

export default Mobile