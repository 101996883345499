import React from 'react'
import styled from 'styled-components';

const TwoImage = ({ Image01, Image02}) => {
  return (
    <OuterContainer>
      <StyledImage src={Image01} />
      <StyledImage src={Image02} />
    </OuterContainer>
  );
}

  const OuterContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;

    /* 450px 이상 800px 미만 */
    @media (min-width: 450px) and (max-width: 799px) {
      display: none;
    }
    
    /* 450px 미만 */
    @media (max-width: 449px) {
      display: none;
    }
  `;
  
  const StyledImage = styled.img`
    width: 49%;
    height: auto;
    object-fit: contain;
  `;
  

export default TwoImage