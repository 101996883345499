import React from 'react'
import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContent from '../../components/header/HeaderContent'
import COLOR from '../../global/Color'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import FullImage from '../../components/inner/venderProduct/FullImage'
import SubHeading from '../../components/inner/venderProduct/SubHeading'

import GoodixImage01 from '../../assets/vendorProduct/GoodixImage01.png';
import TableImage01 from '../../assets/vendorProduct/GoodixTable01.png'
import TableImage02 from '../../assets/vendorProduct/GoodixTable02.png'
import TableImage03 from '../../assets/vendorProduct/GoodixTable03.png'
import TableImage04 from '../../assets/vendorProduct/GoodixTable04.png'
import TableImage05 from '../../assets/vendorProduct/GoodixTable05.png'
import TableImage06 from '../../assets/vendorProduct/GoodixTable06.png'
import GoodixTable from '../../components/inner/venderProduct/GoodixTable'
import styled from 'styled-components'
import GoodixTableBox from '../../components/inner/venderProduct/GoodixTableBox'

const GoodixTableItem01 = [
  {
    image: TableImage02,
    title: 'Ultrasonic Fingerprint Sensor',
    text: ['Ultrasonic Fingerprint Sensor는 3D 지문 정보를 인식하여 지문 인증의 보안을 강화하는 Solution을 제공합니다.']
  }
]
const GoodixTableItem02 = [
  {
    image: TableImage01,
    title: 'Optical IN-DISPLAY Fingerprint Sensor',
    text: ['현재 가장 많은 분야에 적용된 Optical IN-DISPLAY Fingerprint Sensor로 Bezel-less design에 적합 합니다.']
  },
]
const GoodixTableItem03 = [
  {
    image: TableImage03,
    title: 'Capacitive Fingerprint Sensor',
    text: ['Side-key, 전면/후면 장착 Solution을 포함하여, Capacitive Fingerprint Sensor는 다양한 Smart Device에 널리 적용됩니다.']
  }
]

const GoodixTableItem04 = [
  {
    image: TableImage04,
    title: 'Touch Controller for Mobile',
    text: ['Goodix의 Mobile용 Touch Controller는 다양한 Display (LCD/ AMOLED) 에 적용 가능하며 다양한 크기의 Display 에 적적의 Touch Solution 을 제공합니다.']
  },
]
const GoodixTableItem05 = [
  {
    image: TableImage05,
    title: 'Touch Controller for IT (Tablet/ Laptops)',
    text: ['다양한 제품의 Tablet 및 다양한 크기의 Laptop device에 완벽하게 대응 가능하며, 최고의 Active Stylus 성능을 갖추고 있습니다.']
  },
]
const GoodixTableItem06 = [
  {
    image: TableImage06,
    title: 'Touch Controller for Automotive',
    text: ['8~17인치의 자동차 디스플레이 화면을 지원하는 제품들은 다양한 화면 비율에 유연하게 적응합니다.']
  }
]

const TouchScreenHeaderData = ["Part Number", "Touch Points", "Channels", "Screen Size", "Report Rate", "Package", "Chip Size", "Interface"];
const TouchScreenRowData = [["GT9895", "10", "17Tx * 34Rx", "6.7\"", "360Hz", "BGA72", "4.5 × 4.5mm", "IIC / I3C / SPI"],["GT9916", "10", "19Tx * 40Rx", "7.1\"", "360Hz", "BGA88", "5 * 5mm", "IIC / I3C / SPI"]];

const TouchPedHeaderData = ["Part Number", "Channels", "Interface", "DOME Key", "Package", "Chip Size", "System Support", "Supply Voltage"];
const TouchPedRowData = [
  ["GT7863", "16Tx * 27Rx", "I2C / PS2", "Support", "QFN60", "6 * 6 * 0.6mm", "Windows / Chrome", "AVDD 2.8 / 3.0 / 3.3V"],
  ["GT7868Q", "22Tx * 38Rx", "I2C", "Support", "QFN88", "10 * 10 * 0.75mm", "Windows / Chrome", "AVDD 2.8 / 3.0 / 3.3V DVD 1.8V"]
];

const ActiveStylusHeaderData = ["Part Number", "Channels", "Interface", "Support Protocols", "Tx Voltage", "Supply Voltage"];
const ActiveStylusRowData = [
  ["GP850", "2Tx * 1Rx", "I2C", "HPP3.x, MPP2.0, USI1.0, GPP", "15 / 20 / 30 / 40V", "2.2V"]
];

const UltrasonicFingerprintHeaderData = ["Part Number", "Status", "Applications", "Product Type", "FOV", "Module Size", "Supply Voltage", "Interface"];
const UltrasonicFingerprintRowData = [
  ["GUM5228", "Active", "Under Flexible AMOLED Display", "Ultrasonic", "5mm * 5mm", "16.85mm * 13.4mm * 0.17mm", "2.6V ~ 3.6V", "SPI"]
];

const OpticalInDisplayHeaderData = ["Part Number", "Status", "Applications", "Product Type", "FOV", "Module Size", "Supply Voltage", "Interface"];
const OpticalInDisplayRowData = [
  ["GW9558", "Active", "Under AM OLED Display", "Optical Lens Type", "6.5mm * 6.5mm", "7.25mm * 4.8mm * 4.2mm", "3.1 ~ 3.6V", "SPI"],
  ["GW9578", "Active", "Under AM OLED Display", "Optical Lens Type", "6.5mm * 6.5mm", "5.84mm * 4.63mm * 4.2mm", "3.1 ~ 3.6V", "SPI"]
];

const CapacitiveHeaderData = ["Part Number", "Status", "Applications", "Package Size", "Supply Voltage", "Interface"];
const CapacitiveRowData = [
  ["GF3626", "Active", "Coating side key", "15 * 2.56 * 1.2", "2.8V ~ 3.6V", "SPI"],
  ["GF3956", "Active", "Coating side key", "14.3 * 2.4 * 1.3", "2.8V ~ 3.6V", "SPI"]
];


const Goodix = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Goodix</HeaderTitle>
        <HeaderContent>
          <span style={{color: COLOR.MAGORELLEBLUE, fontWeight: 700}}>Goodix
            <span style={{color: COLOR.BLACK, fontWeight: 300, display: 'inline-block'}}>
            </span>
          </span>(<a style={{textDecoration: 'underline', color: 'black'}} href='http://www.goodix.com' target='_blank'>http://www.goodix.com</a>)는 2002년 설립되어, <br></br>
          세계적으로 Human Interface 와 Biometric Technologies 를 선도하는 기업으로써 <br></br>
          Mobile Phone, Tablet, Laptops, Wearable device 들의 Touch interface 및 Fingerprint Solution의 최고의 기술력을 보유하고 있습니다. <br />
        </HeaderContent>

        <HeaderContentSmall>
          다양하고 많은 경험을 바탕으로 세계 유수의 기업들에게 최고 및 최적의 성능을 제공합니다.<br></br>
          특히 Touch 및 Fingerprint Solution 분야에서 혁신적인 성능을 통해 가치를 향상시키고 있습니다.
        </HeaderContentSmall>

        <FullImage Item={GoodixImage01}/>

        <SubHeading>Fingerprint Solution</SubHeading>

        <HeaderContentSmall>
          Goodix는 보편적으로 사용되는 Fingerprint Solution 공급업체로써 보안을 최우선으로 생각합니다. <br />
          Ultrasonic(초음파) Fingerprint Sensor, Optical IN-DISPLAY(광학 디스플레이 내장) Fingerprint Sensor 및 Capacitive(정전식) Fingerprint Sensor를 포괄하는 광범위한 솔루션을 제공합니다
        </HeaderContentSmall>

        <GoodixTableBox data={GoodixTableItem01}/>
        <GoodixTable HeaderData={UltrasonicFingerprintHeaderData} RowData={UltrasonicFingerprintRowData}/>
        <GoodixTableBox data={GoodixTableItem02}/>
        <GoodixTable HeaderData={OpticalInDisplayHeaderData} RowData={OpticalInDisplayRowData}/>
        <GoodixTableBox data={GoodixTableItem03}/>
        <GoodixTable HeaderData={CapacitiveHeaderData} RowData={CapacitiveRowData}/>

        <div style={{height: '1%'}}></div>

        <SubHeading>Touch Controllers</SubHeading>

        <HeaderContentSmall>
          Goodix는 최고의 Human-interface Solution으로써 진정한 Touch 경험을 제공합니다. <br />
          또한 전 세계적으로 10억 개 이상의 장치에 적용되어 있고 Display 기술과 Smart mobile device의 발전과 더불어 혁신적인 기술력으로 소비자에게 높은 만족도를 제공합니다.
        </HeaderContentSmall>
        
        <GoodixTableBox data={GoodixTableItem04}/>
        <GoodixTable HeaderData={TouchScreenHeaderData} RowData={TouchScreenRowData}/>
        <GoodixTableBox data={GoodixTableItem05}/>
        <GoodixTable HeaderData={TouchPedHeaderData} RowData={TouchPedRowData}/>
        <GoodixTableBox data={GoodixTableItem06}/>
        <GoodixTable HeaderData={ActiveStylusHeaderData} RowData={ActiveStylusRowData}/>

        <div style={{height: '80px'}} />
        
      </HeaderContainer>

    </>
  )
}

const TableBox = styled.div`
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`

export default Goodix