import React from 'react'
import SIZE from '../../../global/Size'
import COLOR from '../../../global/Color'
import styled from 'styled-components'

const Summary = () => {
  return (
    <OuterContainer>
      <Column>
        <Title>모집시기 및 전형방법</Title>
        <Text $top={15}>· 모집시기 : 연중 상시 모집합니다.</Text>
        <Text $top={20}>· 전형방법 : 1차 - 서류전형 / 2차 - 면접</Text>
      </Column>
      <Column>
        <Title>제출서류 및 지원방법</Title>
        <Text $top={15}>· 제출서류 : 이력서, 자기소개서</Text>
        <Text $top={20}>· 지원방법 : e-mail 접수 <UnderlinedText>(jalee@eplus.co.kr)</UnderlinedText></Text>
      </Column>
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  
  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1000px) {
    flex-direction: column;
  }
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    flex-direction: column;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    flex-direction: column;
  }

`;

const Column = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
  &:first-child {
    left: 13.2%;
  }

  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1000px) {
    width: 100%;
    &:first-child {
      left: 13.2%;
    }
    &:nth-child(2) {
      left: 13.2%;
      margin-top: 8%;
    }
  }
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    width: 100%;
    &:first-child {
      left: 13.2%;
    }
    &:nth-child(2) {
      left: 13.2%;
      margin-top: 8%;
    }
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    width: 100%;
    &:first-child {
      left: 13.2%;
    }
    &:nth-child(2) {
      left: 13.2%;
      margin-top: 8%;
    }
  }
`;

const Title = styled.span`
  font-size: ${props => props.theme.SIZE.X0LARGE};
  font-weight: 600;
  color: ${props => props.theme.COLOR.MAGORELLEBLUE};
  
  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1000px) {
    font-size: ${props => props.theme.SIZE.XLARGE};
  }
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    font-size: ${props => props.theme.SIZE.XLARGE};
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    font-size: ${props => props.theme.SIZE.LARGE};
  }
`;

const Text = styled.span`
  font-size: ${props => props.theme.SIZE.LARGE};
  position: relative;
  font-weight: 500;
  top: ${props => props.top || 0}px;
  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1000px) {
    font-size: ${props => props.theme.SIZE.LARGE};
  }
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    font-size: ${props => props.theme.SIZE.MEDIUM2};
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    font-size: ${props => props.theme.SIZE.MEDIUM};
  }
`;

const UnderlinedText = styled.span`
  text-decoration: underline;
  color: ${props => props.theme.COLOR.BLUE};
`;

export default Summary