import React from "react";
import styled from "styled-components";
import SIZE from "../../../global/Size";

const IKOTable = ({ Title, Content, bgColor }) => {
  return (
    <Container>
      <TitleBox $bgColor={bgColor}>
        <p style={{ color: '#FFF', fontSize: SIZE.MEDIUM2, fontWeight: '700'}}>{Title}</p>
      </TitleBox>
      <ContentBox>
        <p style={{ paddingLeft: "3%", paddingRight: "3%", lineHeight: 1.7 }}>
          {Content}
        </p>
      </ContentBox>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;

  /* 450px 이상 800px 미만 */
  @media (max-width: 900px) {
    display: grid;
  }
`;

const TitleBox = styled.div`
  width: 20%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ $bgColor }) => $bgColor};
  background-color: ${({ $bgColor }) => $bgColor};

  /* 450px 이상 800px 미만 */
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ContentBox = styled.div`
  width: 80%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #ccc;

  /* 450px 이상 800px 미만 */
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export default IKOTable;
