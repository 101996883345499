import React from 'react'
import styled from 'styled-components';
import SIZE from '../../../global/Size';
import COLOR from '../../../global/Color';

const GoodixTable = ({ HeaderData, RowData }) => {

    return (
        <Table>
            <thead>
            <tr>
                {HeaderData.map((header, index) => (
                <Th key={index}>{header}</Th>
                ))}
            </tr>
            </thead>
            <tbody>
            {RowData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                    <Td key={cellIndex}>{cell}</Td>
                ))}
                </tr>
            ))}
            </tbody>
        </Table>
      );
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 5vh;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  border-top: 1px solid #643FDA;
  padding: 15px;
  text-align: center;
  background-color: #f2f2f2;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 15px;
  text-align: center;
`;
export default GoodixTable