import React, { useState } from 'react'
import backgroundImage from '../../../assets/main/Rectangle1.png'
import styled from 'styled-components';

const MainContainer = ({children}) => {
  
  const [scale, setScale] = useState(1);

  const handleScroll = () => {
    
  }

  return <Container $backgroundImage={backgroundImage}>{children}</Container>;
}

const Container = styled.div`
  width: 100%;
  height: 90vh;
  background-image: url(${props => props.$backgroundImage});
  background-position: center;
  background-size: cover;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    height: 70vh;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    height: 60vh;
  }
  
`;

export default MainContainer