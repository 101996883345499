import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import DefaultContainer from '../../components/container/DefaultContainer'
import Header from '../../components/container/Header'
import MainNaviagation from '../../components/navigation/MainNaviagation'
import InnerNaviagation from '../../components/navigation/InnerNaviagation'
import Footer from '../../components/container/Footer'
import AboutContainer from '../../components/container/company/AboutContainer'
import HeaderTitle from '../../components/inner/HeaderTitle'

import backgroundImage from '../../assets/recruit/RecruitBackgroundImage.png'

import Infomation from './Infomation'

const Recruit = () => {

    const RecruitItem = ["Infomation"];

    return (
        <DefaultContainer>
            <Header headerBackgroundImage={backgroundImage}>
                <MainNaviagation />
                    <HeaderTitle>Recruit</HeaderTitle>
                <InnerNaviagation item={RecruitItem}/>
            </Header>
    
            <AboutContainer>
              <Routes>
                <Route path='/' element={<Navigate to="infomation" replace={true}/> }/>
                <Route path='/infomation' element={<Infomation/>} />
              </Routes>
            </AboutContainer>
    
            <Footer />
    
        </DefaultContainer>
      )
}


export default Recruit