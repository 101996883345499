import React from 'react'
import COLOR from '../../global/Color'
import styled from 'styled-components'

const HeaderContainer = ({children, Image}) => {
  return (
    <HeaderContainerStyled>
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </HeaderContainerStyled>
  )
}

const HeaderContainerStyled = styled.div`
  width: auto;
  height: auto;
  padding-top: 90px;
  padding-bottom: 40px;
  position: relative;
  background-color: ${props => props.theme.COLOR.WHITE};
  padding-left: 13.5%;
  padding-right: 13.5%;
  display: flex;
  flex-direction: row;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export default HeaderContainer