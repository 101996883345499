import React from "react";
import styled from "styled-components";
import SIZE from "../../../global/Size";

const QCTportfolio = ({ Image, TitleText, List, herf }) => {
  return (
    <Container>
      <Icon src={Image} />
      <TextBox>
        <Title>{TitleText}</Title>
        {List.map((item) => (
          <Content>{item}</Content>
        ))}
        <li>
          <a
            style={{
              textDecoration: "underline",
              color: "blue",
              right: "14vw",
            }}
            href={herf}
            target="_blank"
          >
            More Details +
          </a>
        </li>
      </TextBox>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding-bottom: 50px;
  justfiy-content: flex-start;
  align-items: flex-start;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    padding-top: 40px;
    padding-bottom: 20px;
    flex-direction: column;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    padding-top: 40px;
    padding-bottom: 20px;
    flex-direction: column;
  }
`;

const Icon = styled.img`
  width: 30%;
  height: auto;
  display: flex;
  object-fit: contain;
  padding-right: 10%;
`;

const TextBox = styled.div`
  width: 70%;
  height: auto;
  diplay: flex;
  flex-direction: colums;
`;

const Title = styled.p`
  width: 100%;
  height: auto;
  font-size: 20px;
  font-weight: 700;
`;

const Content = styled.li`
  width: 100%;
  height: auto;
  font-size: 16px;
  line-height: 1.6;
`;

export default QCTportfolio;
