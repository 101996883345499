import React from 'react'
import COLOR from '../../../global/Color'
import styled from 'styled-components';
import LogoImage from '../../../assets/eplusLogo.png'


const MainLogo = ({children}) => {
  return (
    <StyledButton>
      {children}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-color: ${props => props.theme.COLOR.CLEAR};
  border: 0;

  /* 450px 이상 1000px 미만 */
  @media (min-width: 450px) and (max-width: 1000px) {
  width: 30%;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    width: 40%;
  }
`;


export default MainLogo