import React from 'react'
import COLOR from '../../global/Color'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContent from '../../components/header/HeaderContent'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import ContentContainer from '../../components/content/ContentContainer'
import ContentTitle from '../../components/content/ContentTitle'
import ContentBox from '../../components/content/ContentBox'
import InnerItem from '../../components/inner/company/InnerItem'
import ContentContainerClear from '../../components/content/ContentContainerClear'
import ContentTitleBlack from '../../components/content/ContentTitleBlack'
import TableBox from '../../components/inner/company/TableBox'

import backgroundImage from '../../assets/company/aboutbackgroundImage.png'

import TableImage01 from '../../assets/company/why01.png'
import TableImage02 from '../../assets/company/why02.png'
import TableImage03 from '../../assets/company/why03.png'
import TableImage04 from '../../assets/company/why04.png'


const data = [
  {
    image: TableImage01,
    text: [
      '· Well-experienced and motivated sales & engineering team',
      '· Founding member have over 70 years experience in Semiconductor and embedded Solutions'
    ]
  },
  {
    image : TableImage02,
    text: [
      '· Good understanding of international business practices and cultures',
      '· Right interfaces & communications between customers and vendors'
    ]
  },
  {
    image : TableImage03,
    text: [
      '· Willingly to invest resources for long-term relationship',
      '· Infomation sharing and market trend feedback for roadmap'
    ]
  },
  {
    image : TableImage04,
    text: [
      '· Efficient and skilful design work',
      '· Deep relationships with a large number of customers in multimedia and telecommunication industry'
    ],
  }
]

const About = () => {

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>About Eplus</HeaderTitle>
        <HeaderContent>
          <span style={{color: COLOR.MAGORELLEBLUE, fontWeight: 700}}>(주)이프러스</span>는 핵심반도체와 Embedded 칩솔루션이 요구되는 인터넷, 정보통신기기 및<br></br>
          멀티미디어, 디지탈 가전분야에서 여러 고객의 동반자가 되어 한국 전자 산업계의 발전에 <br></br>
          많은 기여를 하고 있는 <span style={{fontWeight: 700}}>반도체 유통 전문 회사</span>입니다.
        </HeaderContent>
        
        <HeaderContentSmall>
          디지털화와 혁명적인 정보통신의 패러다임이 끊임없이 변화되는 21C에 당사 고객이 세계의 유수한 경쟁 업체들과 어깨를 견줄 수 있도록 <br></br>
          최고의 솔루션 제공과 최상의 기술지원을 통해 (주)이프러스의 임직원 모두 하나가 되어 적극적으로 Win-Win 협력관계가 되도록 부단히 노력하는 파트너가 되겠습니다.
        </HeaderContentSmall>

        <HeaderContentSmall>
          당사는 첨단 반도체를 포함한 주요부품 공급뿐 아니라 세계유수의 최첨단 신기술 소재와 신속한 기술지원, 마켓팅분야의 컨설팅까지 고객을 우선하는 <br></br>
          End-to-End 토탈 솔루션을 제공하여 (주)이프러스를 선택한 고객이 처음부터 끝가지 만족할 수 있도록 최대한의 협력과 지원을 다할 것을 약속합니다.
        </HeaderContentSmall>

      </HeaderContainer>

      <ContentContainer backgroundImage={backgroundImage}>
        <ContentTitle>Business Direction</ContentTitle>
        <ContentBox>
          <div style={{width: '73%', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(2, 1fr)', gap: 20}}>
            <InnerItem Title={'Supply Technology Value - added Solutions'}
              Content1={'· Working together with customers for optimum solutions'}
              Content2={'· Providing technical guidance to system designers'}
              Content3={'· Introducing high-tech new products and services in time'}
            />
            <InnerItem Title={'Offer specific distribution for particular marketplaces'}
                Content1={'· Specializing multimedia and communication fields'}
                Content2={'· Supplying differentiated information and customer services'}
                Content3={'· Guiding vendors through market & customer’s needs'}
            />
            <InnerItem Title={'Provide accurate forecats'}
              Content1={'· Following up forecasts for PO’s in time'}
              Content2={'· Maximizing customer services with on-time delivery'}
              Content3={'· Reporting a comprehensive market survey frequently'}
            />
            <InnerItem Title={'Establish professional communications'}
              Content1={'· Enhancing bridged accessibility between customers and vendors'}
              Content2={'· Responding all queries within 24 hours'}
              Content3={'· Exchanging professional feedbacks'}
          />
          </div>
        </ContentBox>

      </ContentContainer>

      <ContentContainerClear>
        <ContentTitleBlack>Why eplus?</ContentTitleBlack>
        <ContentBox>
          <TableBox data={data}/>
        </ContentBox>
      </ContentContainerClear>

    </>
  )
}
export default About