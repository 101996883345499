import React from 'react'
import styled from 'styled-components';

const SecondInnerBox = ({children}) => {
  return (
    <Container>
      {children}
    </Container>
  );
}

const Container = styled.div`
  width: 70%;
  height: 55%;
  position: relative;
  top: 20%;
  left: 13%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    top: 15%;
    height: 70%;
    grid-template-columns: repeat(2, 1fr);
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    top: 10%;
    height: 80%;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default SecondInnerBox