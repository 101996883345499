import React from 'react'
import styled from 'styled-components';

const LeftImageTitleBox = ({children, Image, TitleText}) => {
    return (
        <OuterContainer>
          <ImageContainer>
            <StyledImage src={Image} />
          </ImageContainer>
          <Spacer />
          <ContentContainer>
            <TitleContainer>
              <Title>{TitleText}</Title>
            </TitleContainer>
            <ChildrenContainer>
              {children}
            </ChildrenContainer>
          </ContentContainer>
        </OuterContainer>
      );
    }
      
      const OuterContainer = styled.div`
        width: 100%;
        height: auto;
        display: flex;
        padding-top: 50px;
        padding-left: 20px;
        padding-right: 20px;

        /* 450px 이상 800px 미만 */
        @media (min-width: 450px) and (max-width: 799px) {
          flex-direction: column;
        }
        
        /* 450px 미만 */
        @media (max-width: 449px) {
          flex-direction: column;
        }
      `;
      
      const ImageContainer = styled.div`
        width: 30%;
        height: auto;

        /* 450px 이상 800px 미만 */
        @media (min-width: 450px) and (max-width: 799px) {
          width: 100%;
        }
        
        /* 450px 미만 */
        @media (max-width: 449px) {
          width: 100%;
        }
      `;
      
      const StyledImage = styled.img`
        width: 100%;
        height: auto;
      `;
      
      const Spacer = styled.div`
        width: 5%;
      `;
      
      const ContentContainer = styled.div`
        width: 65%;
        height: 100%;

        /* 450px 이상 800px 미만 */
        @media (min-width: 450px) and (max-width: 799px) {
          width: 100%;
        }
        
        /* 450px 미만 */
        @media (max-width: 449px) {
          width: 100%;
        }
      `;
      
      const TitleContainer = styled.div`
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 30px;
      `;
      
      const Title = styled.span`
        font-weight: 600;
        font-size: ${props => props.theme.SIZE.X05LARGE};
      `;
      
      const ChildrenContainer = styled.div`
        width: 100%;
        height: 80%;
      `;      

export default LeftImageTitleBox