import React from 'react'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import SubHeading from '../../components/inner/venderProduct/SubHeading'
import FullImage from '../../components/inner/venderProduct/FullImage'
import ImageTextPanel from '../../components/inner/technologies/ImageTextPanel'

import AutomotiveImage01 from '../../assets/technologies/automotiveImage01.png'
import AutomotiveImage02 from '../../assets/technologies/automotiveImage02.png'
import AutomotiveImage03 from '../../assets/technologies/automotiveImage03.png'


const Automotive = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Automotive</HeaderTitle>

        <div style={{height: '60px'}}/>
        
        <HeaderContentSmall>
          Vehicle camera was used as a video recorder which can improve the driving pleasure and collect accident evidence. <br></br>
          With the development of technologies like panorama stitching, ADAS, automatic driving, vehicle camera application has been upgraded.
        </HeaderContentSmall>
        <HeaderContentSmall>
          Based on the computer vision technology, the camera can collect more information from the video and enable the control system to make decisions, <br></br>
          leads to a better experience of driving and improvement of safety.
        </HeaderContentSmall>

        <FullImage Item={AutomotiveImage01}/>

        <SubHeading>Technology Highlight</SubHeading>
        <HeaderContentSmall>
          · Low-Power, High-Quality, FHD, 4K UHD Video <br></br>
          · Automotive-level certification and optional front ADAS features available <br></br>
          · State-of-the-Art Codec <br></br>
          · Multi-channel recorders <br></br>
          · High Dynamic Range (HDR) <br></br>
          · Intelligent Video Analytics
        </HeaderContentSmall>

        <div style={{height: '20px'}}/>

        <SubHeading>Eplus Solution</SubHeading>

        <ImageTextPanel 
          Image={AutomotiveImage02}
          Title={'OmniVision'}
          Link={'http://www.ovt.com'}
          Text={<>
            Driver distraction is on the rise, and advanced driver assistance systems (ADAS) are becoming mainstream. <br></br>
            Semi-autonomous and fully autonomous vehicles are not far off on the horizon. <br></br>
            Vision systems are at the heart of ADAS, powered by major technological advancements in CMOS image sensors. <br></br>
            These include camera-enabled systems for automatic emergency braking, autonomous driving, video mirror, rear view, <br></br>
            360-degree surround view and driver monitoring.
          </>}/>

        <ImageTextPanel 
          Image={AutomotiveImage03}
          Title={'Sierra wireless'}
          Link={'http://www.sierrawireless.com'}
          Text={<>
            Automakers are using high-speed wireless connectivity to bring sophisticated software and services to the vehicle. <br></br>
            These IoT connections and the “Big Data” they generate can help OEMs continually improve their vehicles and <br></br>
            develop new applications to enhance the driving experience.
          </>}/>

      </HeaderContainer>
    </>
  )
}

export default Automotive