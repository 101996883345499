import React from 'react'
import styled from 'styled-components';
import LogoImage from '../../assets/eplusLogo.png'

const Footer = () => {
  return (
    <OuterContainer>
      <InnerLogoImage src={LogoImage} alt='로고'/>
      <InnerContainer>
        경기도 성남시 분당구 판교로 255번길 9-22 우림 W-City 708호 &nbsp;&nbsp; | &nbsp;&nbsp; Tel : 031-628-8600 &nbsp;&nbsp; | &nbsp;&nbsp; Fax : 031-628-8601 <br/>
        #708, Woorim W-City, 9-22, 255 Pangyo road, Bundang-gu, Sungnam-si, Kyunggi-do, Korea 13486 &nbsp;&nbsp; | &nbsp;&nbsp; Tel : +82-31-628-8600 &nbsp;&nbsp; | &nbsp;&nbsp; Fax : -82-31-628-8601 <br/>
        COPYRIGHT © 1997 EPLUS CO., LTD. ALL RIGHTS RESERVED
      </InnerContainer>
      <InnerContainerMedia> Woorim W-City, 9-22, 255 Pangyo road, Bundang-gu, Sungnam-si, Kyunggi-do, Korea 13486</InnerContainerMedia>
      <InnerContainerMedia> Tel : +82-31-628-8600</InnerContainerMedia>
      <InnerContainerMedia> Fax : -82-31-628-8601</InnerContainerMedia>
      <InnerContainerMedia> COPYRIGHT © 1997 EPLUS CO., LTD. ALL RIGHTS RESERVED</InnerContainerMedia>
      <InnerLogoImageMedia src={LogoImage} alt='로고'/>
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  width: 100%;
  height: 180px;
  background-color: ${props => props.theme.COLOR.BLACKPEARL};
  color: ${props => props.theme.COLOR.WHITE};
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 400;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    flex-direction: column;
    height: 300px;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    flex-direction: column;
    height: 300px;
  }
`;

const InnerContainer = styled.div`
  width: 70%;
  height: 100%;
  align-content: center;
  line-height: 1.6;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`;

const InnerLogoImage = styled.img`
  width: 20%;
  height: 100%;
  object-fit: contain;
  border: 0px;
  transform: scale(0.5);

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`

const InnerContainerMedia = styled.div`
  display: none;
  width: 70%;
  padding-bottom: 3%;
  font-size: medium;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: block;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    display: block;
  }
`

const InnerLogoImageMedia = styled.img`
  display: none;
  width: 20%;
  height: auto;
  object-fit: contain;
  border: 0px;
  transform: scale(0.8);

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    padding-top: 20px;
    transform: scale(1);
    display: block;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    padding-top: 20px;
    transform: scale(1.5);
    display: block;
  }
`


export default Footer