import React from 'react'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import SubHeading from '../../components/inner/venderProduct/SubHeading'
import FullImage from '../../components/inner/venderProduct/FullImage'
import ImageTextPanel from '../../components/inner/technologies/ImageTextPanel'
import ThreeImagePanel from '../../components/inner/technologies/ThreeImagePanel'
import LastHeaderContentSmall from '../../components/header/LastHeaderContentSmall'

import AutomotiveImage02 from '../../assets/technologies/automotiveImage02.png'
import MedicalImage01 from '../../assets/technologies/medicalImage01.png';
import MedicalImage_02 from '../../assets/technologies/medicalImage_02.png'
import MedicalImage02 from '../../assets/technologies/medicalImage02.png';
import MedicalImage03 from '../../assets/technologies/medicalImage03.png';
import MedicalImage04 from '../../assets/technologies/medicalImage04.png';

const Medical = () => {
  return (
    <HeaderContainer>
      <HeaderTitle>Medical</HeaderTitle>
      <div style={{height: '60px'}}/>
      <HeaderContentSmall>
        The medical markets for endoscopy imaging solutions are growing rapidly, driven by a need for minimally invasive diagnostic and therapeutic procedures, <br></br>
        socioeconomic trends such as an aging population, and rising medical costs that are pushing procedures back into the physician’s office. <br></br>
        Single-use endoscopes and catheters can have advantages over reusable devices that can have sterilization issues.
      </HeaderContentSmall>

      <FullImage Item={MedicalImage01}/>
      <FullImage Item={MedicalImage_02}/>

      <ThreeImagePanel Image01={MedicalImage02} Image02={MedicalImage03} Image03={MedicalImage04}>
        <LastHeaderContentSmall>
          Neurological, ophthalmic, cardiac, spinal, urologic and gynecologic <br></br>
          imaging procedures require small-outer-diameter endoscopes. <br></br>
          Ultra-small CMOS imagers allow chip-on-the-tip designs with higher <br></br>
          resolution, better image quality and lower cost than fiber optics.
        </LastHeaderContentSmall>
      </ThreeImagePanel>

      <SubHeading>Eplus Solution</SubHeading>
      <ImageTextPanel 
          Image={AutomotiveImage02}
          Title={'OmniVision'}
          Link={'http://www.ovt.com'}
          Text={<>
            Driver distraction is on the rise, and advanced driver assistance systems (ADAS) are becoming mainstream. <br></br>
            Semi-autonomous and fully autonomous vehicles are not far off on the horizon. <br></br>
            Vision systems are at the heart of ADAS, powered by major technological advancements in CMOS image sensors. <br></br>
            These include camera-enabled systems for automatic emergency braking, autonomous driving, video mirror, rear view, <br></br>
            360-degree surround view and driver monitoring.
          </>}/>

    </HeaderContainer>
  )
}

export default Medical