import React from "react";
import HeaderContainer from "../../components/header/HeaderContainer";
import HeaderTitle from "../../components/header/HeaderTitle";

import QCTbusiness from "../../assets/vendorProduct/QCTbusiness.png";
import QCTcore from "../../assets/vendorProduct/QCTcore.png";
import QCTmission from "../../assets/vendorProduct/QCTmission.png";
import QCTvision from "../../assets/vendorProduct/QCTvision.png";
import QCToverview from "../../assets/vendorProduct/QCToverview.png";
import QCThistory from "../../assets/vendorProduct/QCThistory.png";

import QCTcellular from "../../assets/vendorProduct/QCTcellular.png";
import QCTautomotive from "../../assets/vendorProduct/QCTautomotive.png";
import QCTsmart from "../../assets/vendorProduct/QCTsmart.png";
import QCTwifi from "../../assets/vendorProduct/QCTwifi.png";
import QCTgnss from "../../assets/vendorProduct/QCTgnss.png";
import QCTsatellite from "../../assets/vendorProduct/QCTsatellite.png";
import QCTantenna from "../../assets/vendorProduct/QCTantenna.png";
import QCTservice from "../../assets/vendorProduct/QCTservice.png";

import QCTpartnerProviders from "../../assets/vendorProduct/QCTpartnerProviders.png";
import QCTpartnerTechnology from "../../assets/vendorProduct/QCTpartnerTechnology.png";
import QCTpartnercarriers from "../../assets/vendorProduct/QCTpartnercarriers.png";
import QCTpartnerRegulators from "../../assets/vendorProduct/QCTpartnerRegulators.png";
import QCTpartnerCenter from "../../assets/vendorProduct/QCTpartnerCenter.png";

import SIZE from "../../global/Size";
import COLOR from "../../global/Color";
import SubHeading from "../../components/inner/venderProduct/SubHeading";
import FullImage from "../../components/inner/venderProduct/FullImage";
import styled from "styled-components";
import HeaderContent from "../../components/header/HeaderContent";
import HeaderContentSmall from "../../components/header/HeaderContentSmall";
import ImageTextSmall from "../../components/inner/technologies/ImageTextSmall";
import QCTportfolio from "../../components/inner/venderProduct/QCTportfolio";
import QCTpartnerTwoImage from "../../components/inner/venderProduct/QCTpartnerTwoImage";
import QCTpartnerCenterImage from "../../components/inner/venderProduct/QCTpartnerCenterImage";

const Quectel = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Quectel</HeaderTitle>
        <HeaderContent>
          <span style={{ color: COLOR.MAGORELLEBLUE, fontWeight: 700 }}>
            Quectel
            <span
              style={{
                color: COLOR.BLACK,
                fontWeight: 300,
                display: "inline-block",
              }}
            ></span>
          </span>
          (
          <a
            style={{ textDecoration: "underline", color: "black" }}
            href="https://www.quectel.com/ko"
            target="_blank"
          >
            https://www.quectel.com/ko
          </a>
          )은 2010년 설립된 이래 독보적인 영업 전략과 추진력으로
          <br />
          단기간에 Worldwide market share No.1을 차지하고 IoT 기술을 선도해오고
          있습니다.
        </HeaderContent>
        <HeaderContentSmall>
          Quectel은 셀룰러 무선 통신 모듈, GNSS 및 안테나와 같은 IoT 솔루션의
          선도적인 공급 업체입니다. Quectel은 장치와 사람을 네트워크와 서비스에
          연결하여 디지털 혁신을 주도하고 더 스마트한 세상을 구축합니다.
          Quectel의 제품은 생활을 보다 편리하고, 효율적이며, 편안하고, 풍부하고
          안전하게 만드는 데 도움이 됩니다.
        </HeaderContentSmall>
        <br />
        <br />
        <SubHeading>Quectel's Corporate Culture</SubHeading>
        <QCTPhotosContainer>
          <ImageTextSmall Image={QCTmission} />
          <ImageTextSmall Image={QCTvision} />
          <ImageTextSmall Image={QCTcore} />
          <ImageTextSmall Image={QCTbusiness} />
        </QCTPhotosContainer>

        <SubHeading>Overview of Quectel as a Company</SubHeading>
        <ImageBoxContainer>
          <FullImage Item={QCToverview} />
        </ImageBoxContainer>
        <p
          style={{
            fontSize: SIZE.MEDIUM,
            lineHeight: 1.7,
            paddingLeft: 10,
            marginTop: -10,
          }}
        >
          Quectel's passion for a smarter world drives us to accelerate loT
          innovation. A highly customer-centric organization, we are a global
          IoT solutions provider backed backed by outstanding support and
          services.
        </p>

        <br />
        <br />
        <br />

        <ImageBoxContainer>
          <SubHeading>History & milestones</SubHeading>
          <FullImage Item={QCThistory} />
        </ImageBoxContainer>

        <br />
        <br />

        <SubHeading>Quectel IoT portfolio</SubHeading>
        <QCTportfolioContainer>
          <QCTportfolio
            Image={QCTcellular}
            TitleText={"Cellular"}
            List={["5G", "4G", "LPWA", "3G/2G"]}
            herf={
              "https://www.quectel.com/product-selector/?_sft_product_supported_technologies=5g"
            }
          />
          <QCTportfolio
            Image={QCTautomotive}
            TitleText={"Automotive"}
            List={[
              "5G/4G",
              "C-V2X",
              "GNSS",
              "Cockpit/ IVI",
              "Wi0Fi / Bluetooth",
            ]}
            herf={
              "https://www.quectel.com/product-selector/?_sft_product_cat=automotive-modules"
            }
          />
          <QCTportfolio
            Image={QCTsmart}
            TitleText={"Smart"}
            List={["5G", "4G", "Edge compute"]}
            herf={
              "https://www.quectel.com/product-selector/?_sft_product_cat=smart-modules"
            }
          />
          <QCTportfolio
            Image={QCTwifi}
            TitleText={"Wi-Fi/Bluetooth"}
            List={[
              "Wi-Fi 7",
              "Wi-Fi 6E",
              "Wi-Fi 6",
              "Wi-Fi 5",
              "Wi-Fi 4",
              "BT 5.X",
              "Sub-G (LoRa/ HaLow)",
            ]}
            herf={
              "https://www.quectel.com/product-selector/?_sft_product_cat=wi-fi-bluetooth-modules"
            }
          />
          <QCTportfolio
            Image={QCTgnss}
            TitleText={"GNSS"}
            List={[
              "DR positioning",
              "RTK positioning",
              "Fusion positioning",
              "Timing",
              "Multiple frequency localization",
              "Single frequency localization",
              "Integrated antenna",
              "IMU",
            ]}
            herf={
              "https://www.quectel.com/product-selector/?_sft_product_cat=gnss-modules-standalone"
            }
          />
          <QCTportfolio
            Image={QCTsatellite}
            TitleText={"Satellite"}
            List={["5G NTN", "Proprietary"]}
            herf={
              "https://www.quectel.com/product-selector/?_sft_product_cat=satellite-modules"
            }
          />
          <QCTportfolio
            Image={QCTantenna}
            TitleText={"Antenna"}
            List={[
              "Embedded antenna",
              "External antenna",
              "Cable & accessories",
              "Automotive antenna",
              "mmWave antenna",
            ]}
            herf={
              "https://www.quectel.com/product-selector/?_sft_product_cat=antennas"
            }
          />
          <QCTportfolio
            Image={QCTservice}
            TitleText={"Services"}
            List={[
              "Antenna (consultation, desing, evaluation and testing)",
              "Certification & testing",
              "RTK Correction Services",
            ]}
            herf={"https://www.quectel.com/product-selector/"}
          />
        </QCTportfolioContainer>
        <p
          style={{
            fontSize: SIZE.XLARGE,
            backgroundColor: "#00FF",
            textAlign: "center",
            padding: "1vh",
            fontWeight: 700,
            color: "#FFF",
          }}
        >
          Comprehensivce IoT product and services portfolio, enabling a complete
          solution
        </p>

        <br />
        <br />
        
        <SubHeading>Quectel is a trusted partner</SubHeading>
        <QCTpartnerContainer>
          <QCTpartnerTwoImage Image01={QCTpartnerProviders} Image02={QCTpartnerTechnology} />
          <QCTpartnerCenterImage Image={QCTpartnerCenter} />
          <QCTpartnerTwoImage Image01={QCTpartnercarriers} Image02={QCTpartnerRegulators} />
        </QCTpartnerContainer>
      </HeaderContainer>
    </>
  );
};

const ImageBoxContainer = styled.div`
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 1300px) {
    display: none;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`;

const TextBoxContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding-top: 80px;
  padding-bottom: 50px;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    padding-top: 40px;
    padding-bottom: 20px;
    flex-direction: column;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    padding-top: 40px;
    padding-bottom: 20px;
    flex-direction: column;
  }
`;

const QCTPhotosContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 40px;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    grid-template-columns: repeat(2, 1fr);
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const QCTportfolioContainer = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    grid-template-columns: repeat(2, 1fr);
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const QCTpartnerContainer = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;
  align-items: center;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    grid-template-columns: repeat(2, 1fr);
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default Quectel;
