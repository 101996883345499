import React from "react";
import styled from "styled-components";

const QCTpartnerCenterImage = ({ Image }) => {
  return (
    <Container>
      <PartnerImage src={Image} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`;

const PartnerImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`;

export default QCTpartnerCenterImage;
