import React, { useEffect, useState } from "react";
import "./NavigationEffect.css";
import { Link } from "react-router-dom";
import styled from "styled-components";

const InnerNaviagation = ({ item = [] }) => {
  return (
    <FooterContainer $length={item.length}>
      {item.map((inner, index) => (
        <StyledLink
          className="animated-button"
          to={`${inner.toLowerCase()}`}
          key={index}
        >
          {inner}
        </StyledLink>
      ))}
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${(props) => props.theme.COLOR.MAGORELLEBLUECLEAR};
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 70px;
  z-index: 998;

  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
    display: grid;
    grid-template-columns: ${({ $length }) =>
      $length > 6
        ? `repeat(4, 1fr)`
        : $length > 4
        ? `repeat(3, 1fr)`
        : $length === 1
        ? `repeat(1, 1fr)`
        : $length === 2
        ? `repeat(2, 1fr)`
        : `repeat(3, 1fr)`};
    grid-template-rows: ${({ $length }) =>
      $length > 3 ? `repeat(2, 1fr)` : `repeat(1, 1fr)`};
    height: ${({ $length }) => ($length > 3 ? `120px` : `60px`)};
    gap: 10px;
  }

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: grid;
    gap: 10px;
    grid-template-columns: ${({ $length }) =>
      $length > 6
        ? `repeat(4, 1fr)`
        : $length > 4
        ? `repeat(3, 1fr)`
        : $length === 1
        ? `repeat(1, 1fr)`
        : $length === 2
        ? `repeat(2, 1fr)`
        : `repeat(3, 1fr)`};
    grid-template-rows: ${({ $length }) =>
      $length > 3 ? `repeat(2, 1fr)` : `repeat(1, 1fr)`};
    height: ${({ $length }) => ($length > 4 ? `120px` : `60px`)};
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    display: grid;
    grid-template-columns: ${({ $length }) =>
      $length > 6
        ? `repeat(3, 1fr)`
        : $length > 4
        ? `repeat(3, 1fr)`
        : $length === 1
        ? `repeat(1, 1fr)`
        : $length === 2
        ? `repeat(2, 1fr)`
        : `repeat(3, 1fr)`};
    grid-template-rows: ${({ $length }) =>
      $length > 6
        ? `repeat(3, 1fr)`
        : $length > 3
        ? `repeat(2, 1fr)`
        : `repeat(1, 1fr)`};
    gap: 10px;
    height: ${({ $length }) =>
      $length > 6
        ? `150px`
        : $length > 4
        ? `120px`
        : $length === 3
        ? `60px`
        : `60px`};
  }
`;

const StyledLink = styled(Link)`
  display: flex; /* Flexbox 활성화 */
  justify-content: center; /* 가로 정렬 */
  align-items: center; /* 세로 정렬 */
  color: ${(props) => props.theme.COLOR.WHITE};
  text-decoration: none;
  width: 8%;
  height: 100%;
  font-weight: 600;
  font-size: ${(props) => props.theme.SIZE.MEDIUM};
  background-color: ${(props) => props.theme.COLOR.CLEAR};

  /* 반응형 스타일 */
  @media (min-width: 800px) and (max-width: 1299px) {
    width: 100%;
  }
  @media (min-width: 450px) and (max-width: 799px) {
    width: 100%;
  }
  @media (max-width: 449px) {
    width: 100%;
  }
`;

export default InnerNaviagation;
