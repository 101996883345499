import React from "react";
import HeaderContainer from "../../../components/header/HeaderContainer";
import HeaderTitle from "../../../components/header/HeaderTitle";
import BydImage01 from "../../../assets/vendorProduct/bydImage01_1.png";
import BydImage02 from "../../../assets/vendorProduct/bydImage02.png";
import BydImage05 from "../../../assets/vendorProduct/bydImage05.png";

import BydTableImage01 from "../../../assets/vendorProduct/bydTableImage01.png";
import BydTableImage02 from "../../../assets/vendorProduct/bydTableImage02.png";
import BydTableImage03 from "../../../assets/vendorProduct/bydTableImage03.png";
import BydTableImage04 from "../../../assets/vendorProduct/bydTableImage04.png";

import LeftImageTitleBox from "../../../components/inner/venderProduct/LeftImageTitleBox";
import SIZE from "../../../global/Size";
import COLOR from "../../../global/Color";
import SubHeading from "../../../components/inner/venderProduct/SubHeading";
import FullImage from "../../../components/inner/venderProduct/FullImage";
import styled from "styled-components";
import HeaderContent from "../../../components/header/HeaderContent";
import HeaderContentSmall from "../../../components/header/HeaderContentSmall";

const Byd = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderTitle>BYD</HeaderTitle>
        <HeaderContent>
          <span style={{ color: COLOR.MAGORELLEBLUE, fontWeight: 700 }}>
            BYD
            <span
              style={{
                color: COLOR.BLACK,
                fontWeight: 300,
                display: "inline-block",
              }}
            ></span>
          </span>
          (
          <a
            style={{ textDecoration: "underline", color: "black" }}
            href="https://kr.byd.com"
            target="_blank"
          >
            https://kr.byd.com
          </a>
          )는 모두의 더 나은 삶을 위해 기술 혁신을 이루어가는 글로벌 첨단 기술
          기업입니다. <br />
        </HeaderContent>
        <HeaderContentSmall>
          1995년 중국 선전에서 배터리 회사로 시작해 현재 전세계 70여개 이상의
          국가에서 자동차, 전자, 신재생에너지, 경전철 등을 주력 사업으로
          전개하고 있습니다. <br />
          에너지 생성 및 저장에서 응용 분야에 이르기까지 전세계적으로 화석 연료
          의존도를 줄이는 배출가스 없는 에너지 해결책 마련에 집중하고 있습니다.{" "}
          <br />
          자동차 사업의 경우, 2022년 친환경차 판매량 186만대를 기록하며, 세계
          전기차 1위 기업으로 부상했으며, 친환경적인 세상을 위한 기술과 성과를
          인정받아 포춘(Fortune)500대 기업에 선정되었습니다. <br />
          현재 홍콩 증권 거래소와 선전 증권 거래소에 상장되어 있습니다.
        </HeaderContentSmall>
        <LeftImageTitleBox
          Image={BydImage01}
          Title={"BYD Pouch 3.2V 21.7Ah LiFePO4 Battery Cell"}
        >
          <span style={{ fontSize: SIZE.LARGE }}>
            Lithium iron Phosphate Batterys
          </span>
          <TextBoxContainer>
            <div style={{ width: "40%" }}>
              <span style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Applications
              </span>
              <ul style={{ lineHeight: 1.7 }}>
                <li>Solar system</li>
                <li>Energy storage (ESS)</li>
                <li>Electric vehicles</li>
                <li>Golf carts</li>
                <li>RV</li>
              </ul>
            </div>

            <div>
              <span style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Battery Features
              </span>
              <ul style={{ lineHeight: 1.7 }}>
                <li>High Energy Density</li>
                <li>Long Cycle Life</li>
                <li>Good performance at high/low temperature</li>
                <li>High-rate continuous discharge</li>
              </ul>
            </div>
          </TextBoxContainer>
        </LeftImageTitleBox>

        <ImageBoxContainer>
          <SubHeading>Parameters</SubHeading>
        </ImageBoxContainer>
        <FullImage Item={BydTableImage01} />

        <LeftImageTitleBox
          Image={BydImage02}
          Title={"BYD Pouch 3.2V 21.7Ah LiFePO4 Battery Cell"}
        >
          <span style={{ fontSize: SIZE.LARGE }}>
            Lithium iron Phosphate Batterys
          </span>
          <TextBoxContainer>
            <div style={{ width: "40%" }}>
              <span style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Applications
              </span>
              <ul style={{ lineHeight: 1.7 }}>
                <li> DIY Lifepo4 battery Pack</li>
                <li>Solar system</li>
                <li>Energy storage (ESS)</li>
                <li>Electric vehicles</li>
                <li>Golf carts</li>
                <li>RV</li>
              </ul>
            </div>

            <div>
              <span style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
                Battery Features
              </span>
              <ul style={{ lineHeight: 1.7 }}>
                <li>High Energy Density </li>
                <li>Long Cycle Life </li>
                <li>Good performance at high/low temperature </li>
                <li>High-rate continuous discharge </li>
              </ul>
            </div>
          </TextBoxContainer>
        </LeftImageTitleBox>

        <ImageBoxContainer>
          <SubHeading>Parameters</SubHeading>
        </ImageBoxContainer>
        <FullImage Item={BydTableImage02} />

        <LeftImageTitleBox
          Image={BydImage02}
          Title={"BYD FC4680 3.2V 15Ah LiFePO4 4680 Battery Cell"}
        >
          <span style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
            Standardized
          </span>
          <ul style={{ lineHeight: 1.7 }}>
            <li>
              4680 industry-standard size, which facilitates standardized
              packassembly.
            </li>
            <li>
              Incorporate innovative Laser Cleaning technology and
              central-tabsdesign to reduce internal resistance
            </li>
          </ul>
          <span style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
            High Safety
          </span>
          <ul style={{ lineHeight: 1.7 }}>
            <li>
              Passed the tests of Fire Burning, Nail Penatration, and
              Over-charging.
            </li>
            <li>
              No severe chemical reactions such as fire or explosion appeared
              duringThermal Runaway Test
            </li>
          </ul>
          <span style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
            High Safety
          </span>
          <ul style={{ lineHeight: 1.7 }}>
            <li>
              6000 times at Room Temperature (25℃,0.5/0.5C,70% retention rate)
            </li>
            <li>
              2000 times at High Temperature (45℃,0.510.5C,70% retention rate)
            </li>
          </ul>
        </LeftImageTitleBox>

        <p style={{ fontSize: SIZE.MEDIUM, lineHeight: 1.7, paddingLeft: 10 }}>
          1. Significantly increased battery power (6 times that of the 21700
          battery), reduced battery costs, optimized heat dissipation
          performance, production efficiency, <br></br>
          and charging speed, and further improved energy density and cycle
          performance.
        </p>

        <p style={{ fontSize: SIZE.MEDIUM, lineHeight: 1.7, paddingLeft: 10 }}>
          2. The design of non-polar ears (full-polar ears) greatly shortens the
          distance of electronic movement, reduces internal battery resistance,{" "}
          <br></br>
          and significantly enhances the battery's charging and discharging
          capabilities.
        </p>

        <FullImage Item={BydTableImage03} />

        <LeftImageTitleBox
          Image={BydImage05}
          Title={
            <>
              BYD FC46120P 3.2V 25Ah 46120 Cyclindrical <br></br>
              LiFePO4 Battery Cell
            </>
          }
        >
          <span style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
            Standardized
          </span>
          <ul style={{ lineHeight: 1.7 }}>
            <li>
              4680 industry-standard size, which facilitates standardized
              packassembly.
            </li>
            <li>
              Incorporate innovative Laser Cleaning technology and
              central-tabsdesign to reduce internal resistance
            </li>
          </ul>
          <span style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
            High Safety
          </span>
          <ul style={{ lineHeight: 1.7 }}>
            <li>
              Passed the tests of Fire Burning, Nail Penatration, and
              Over-charging.
            </li>
            <li>
              No severe chemical reactions such as fire or explosion appeared
              duringThermal Runaway Test
            </li>
          </ul>
          <span style={{ fontSize: SIZE.MEDIUM2, color: COLOR.DARKGRAY }}>
            High Safety
          </span>
          <ul style={{ lineHeight: 1.7 }}>
            <li>
              4000 times at Room Temperature (25℃,0.5/0.5C,70% retention rate)
            </li>
            <li>
              2000 times at High Temperature (45℃,0.510.5C,70% retention rate)
            </li>
          </ul>
        </LeftImageTitleBox>

        <p style={{ fontSize: SIZE.MEDIUM, lineHeight: 1.7, paddingLeft: 10 }}>
          1. Significantly increased battery power (10 times that of the 21700
          battery), reduced battery costs, optimized heat dissipation
          performance, production efficiency, <br></br>
          and charging speed, and further improved energy density and cycle
          performance;
        </p>

        <p style={{ fontSize: SIZE.MEDIUM, lineHeight: 1.7, paddingLeft: 10 }}>
          2. The design of non-polar ears (full-polar ears) greatly shortens the
          distance of electronic movement, reduces internal battery resistance,{" "}
          <br></br>
          and significantly enhances the battery's charging and discharging
          capabilities.
        </p>

        <FullImage Item={BydTableImage04} />
      </HeaderContainer>
    </>
  );
};

const ImageBoxContainer = styled.div`
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 1300px) {
    display: none;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`;

const TextBoxContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding-top: 80px;
  padding-bottom: 50px;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    padding-top: 40px;
    padding-bottom: 20px;
    flex-direction: column;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    padding-top: 40px;
    padding-bottom: 20px;
    flex-direction: column;
  }
`;

export default Byd;
