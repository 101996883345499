import React from 'react'
import COLOR from '../../global/Color'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContent from '../../components/header/HeaderContent'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import SubHeading from '../../components/inner/venderProduct/SubHeading'
import SyncommTableBox from '../../components/inner/venderProduct/SyncommTableBox'
import CenterAlignedContainerNoPadding from '../../components/inner/venderProduct/CenterAlignedContainerNoPadding'
import NoPaddingCenterAlignedContainer from '../../components/container/venderProduct/NoPaddingCenterAlignedContainer';
import GoodixTable from '../../components/inner/venderProduct/GoodixTable'

import syncommTableImage01 from '../../assets/vendorProduct/syncommTableImage01.png'
import syncommTableImage02 from '../../assets/vendorProduct/syncommTableImage02.png'
import syncommTableImage03 from '../../assets/vendorProduct/syncommTableImage03.png'
import syncommTableImage04 from '../../assets/vendorProduct/syncommTableImage04.png'
import syncommTableImage05 from '../../assets/vendorProduct/syncommTableImage05.png'
import syncommTableImage06 from '../../assets/vendorProduct/syncommTableImage06.png'
import syncommTableImage07 from '../../assets/vendorProduct/syncommTableImage07.png'
import syncommTableImage08 from '../../assets/vendorProduct/syncommTableImage08.png'
import syncommTableImage09 from '../../assets/vendorProduct/syncommTableImage09.png'
import syncommTableImage10 from '../../assets/vendorProduct/syncommTableImage10.png'
import syncommTableImage11 from '../../assets/vendorProduct/syncommTableImage11.png'
import syncommTableImage12 from '../../assets/vendorProduct/syncommTableImage12.png'
import syncommTableImage13 from '../../assets/vendorProduct/syncommTableImage13.png'
import syncommTableImage14 from '../../assets/vendorProduct/syncommTableImage14.png'
import syncommTableImage15 from '../../assets/vendorProduct/syncommTableImage15.png'
import syncommTableImage16 from '../../assets/vendorProduct/syncommTableImage16.png'
import styled from 'styled-components'

const Syncomm = () => {

  const syncommTableItem = [
    {
      image: syncommTableImage01,
      title: 'LOW Cost',
      text: [
        'Low system BOM cost',
      ]
    },
    {
      image: syncommTableImage02,
      title: 'Driver Free',
      text: [
        'No need PC Driver  /  Plug and play',
      ]
    },
    {
      image: syncommTableImage03,
      title: 'RX Synchronization',
      text: [
        'Audio phase synchronization',
        'among RX'
      ]
    },
    {
      image: syncommTableImage04,
      title: 'LOW Volatge',
      text: [
        'Minimum 2.0V',
        ''
      ]
    },
    {
      image: syncommTableImage05,
      title: '2.4GHz',
      text: [
        'Global ISM unlicensed band',
        ''
      ]
    },
    {
      image: syncommTableImage06,
      title: 'Small Size',
      text: [
        'Compact module  /',
        'Flexible for design-in'
      ]
    },
    {
      image: syncommTableImage07,
      title: 'Short Delay Time',
      text: [
        '<20ms, programmable',
        ''
      ]
    },
    {
      image: syncommTableImage08,
      title: 'Trukey Solution',
      text: [
        'H/W and S/W ready  /',
        'System design  /  Production kits ready'
      ]
    },
    {
      image: syncommTableImage09,
      title: '5GHz',
      text: [
        'Global UNII unlicensed band',
        ''
      ]
    },
    {
      image: syncommTableImage10,
      title: 'Control Back',
      text: [
        'Bi-directional communication',
        '/  Full duplext'
      ]
    },
    {
      image: syncommTableImage11,
      title: 'Coexistence',
      text: [
        'WiFi  /  Cordless phone  /  Bluetooth',
        'Microwave'
      ]
    },
    {
      image: syncommTableImage12,
      title: 'Market Proven Solution',
      text: [
        'Proven solution  /  Global brands adoption',
        ''
      ]
    },
    {
      image: syncommTableImage13,
      title: 'Multiple TX',
      text: [
        'Up to 8 TX  /',
        'Transmission synchronization'
      ]
    },
    {
      image: syncommTableImage14,
      title: 'Long Distance',
      text: [
        'Up to 100 meters',
        ''
      ]
    },
    {
      image: syncommTableImage15,
      title: 'Low Power Consumption',
      text: [
        '<40ma @2.0',
        ''
      ]
    },
    {
      image: syncommTableImage16,
      title: 'Broadcasting',
      text: [
        'Point to multi-points',
        ''
      ]
    },
  ]

  const CapacitiveHeaderData = ["","IA9QH5", "IA3S4"];
  const CapacitiveRowData = [
    ["Type","Tansmitter/Receiver","Tansmitter/Receiver"],
    ["Power Supply","2.0V to 3.6V","2.0V to 3.6V"],
    ["RF Power(Max)","8dBm","7dBm"],
    ["Current Consumption (Max)","60mA","52mA"],
    ["Sensitivity","-82dBm","-80dBm"],
    ["Distance","15m",">30m"],
    ["SNR","98dB","134dB"],
    ["THD+N","-98dB","-59dB"],
    ["Die size","7mm x 7mm x 0.75mm","26mm x 60mm x 1.0mm"],
    ["Antenna Type (Fixed)","PCB","PCB"],
    ["LDO (Fixed)","Included","Not Included"],
    ["Audio Input and Output(Fixed)","I2S&USB&S/PDIF","I2S"],
    ["RF Bandwidth","4~5Ghz","2.4Ghz ISM"],
  ];

  return (
    <>
    <HeaderContainer>
        <HeaderTitle>Syncomm</HeaderTitle>
        <HeaderContent>
        <span style={{color: COLOR.MAGORELLEBLUE, fontWeight: 700}}>Syncomm<span style={{color: COLOR.BLACK, fontWeight: 300, display: 'inline-block'}}></span></span>(<a style={{textDecoration: 'underline', color: COLOR.BLACK}} href='http://www.syncomm.com.tw' target='_blank'>http://www.syncomm.com.tw</a>)은 반도체 설계 전문 회사로 <br></br>
          실시간 무선 digital audio streaming 에 특화된 솔루션을 제공하며, <br></br>
          휴대용 기기부터 다양한 솔루션에 높은 품질의 오디오 전송을 가능케 합니다.
        </HeaderContent>

        <HeaderContentSmall>
          SYNCOMM®은 창의적이고 혁신적인 SYNIC®의 기술로, CD 수준을 능가하는 오디오 품질을 제품에 적용할 수 있게 해 주는 세계시장의 선도주자 입니다. <br></br>
          SYNIC® technology solution features는 전세계적으로 할당되어 있지 않은 2.4Ghz/5GHz 주파수 대역을 사용하여 digital wireless 오디오를 전송을 구현합니다. <br></br>
          당사의 기술은 진보된 아키텍쳐를 활용하여, 뛰어난 coexistence 메커니즘과 함께 유연한 high performance의 무선 전송을 가능하게 합니다.
        </HeaderContentSmall>

        <SubHeading>KEY Factors</SubHeading>
        <HeaderContentSmall>
          시장을 리드하는 무선 오디오 스트리밍 기술 제품의 key factor는 음질, RF 간섭, 전력소모량, 전송가능거리, 지연시간, 비용, 제품의 크기와 다양한 기능들을 포함하고 있습니다. <br></br>
          저희 고객사들은 SYNIC® 의 기술이 앞의 key factor들을 특화된 어플리케이션에 적용할 수 있도록 최적화된 조합을 제공한다는 것을 발견할 수 있습니다.
        </HeaderContentSmall>

        <SubHeading>EVALUATION Kits</SubHeading>
        <HeaderContentSmall>
          reference designs과 기술지원 서비스를 통해 개발시간을 단축하고 용이하게 개발하실 수 있도록 지원하고 있습니다. <br></br>
          저희 솔루션 플랫폼은 Dolby 헤드폰, LCD-TV, 홈시어터, 서라운딩 스피커, 홈 스테레오, 그리고 portable applications을 포함한 다양한 분야에 적용하실 수 있습니다.
        </HeaderContentSmall>
        
        <SubHeading>PARTNERS</SubHeading>
        <HeaderContentSmall>
          SYNCOMM 시장에 신속하게 접근하기 위해 집중하는 고객사에 높은 품질의 서비스를 제공하고, 전세계적인 유통망을 구축하였습니다. <br></br>
          저희의 서비스는 고객사에게 제품개발 및 채널 마케팅과 같은 핵심경쟁을 확보할 수 있도록 지원을 해 드립니다.
        </HeaderContentSmall>

      </HeaderContainer>

      <CenterAlignedContainerNoPadding>
        <SyncommTableBox data={syncommTableItem} />
      </CenterAlignedContainerNoPadding>

      <NoPaddingCenterAlignedContainer>
        <ImageBoxContainer>
          <SubHeading>Summary of Syncomm's 2.4GHz Modules</SubHeading>
          <GoodixTable HeaderData={CapacitiveHeaderData} RowData={CapacitiveRowData}/>
        </ImageBoxContainer>
      </NoPaddingCenterAlignedContainer>
    </>
  )
}

const ImageBoxContainer = styled.div`

  width: 50%;
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`


export default Syncomm