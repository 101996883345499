import React from 'react'
import DefaultContainer from '../../components/container/DefaultContainer'
import Header from '../../components/container/Header'
import MainNaviagation from '../../components/navigation/MainNaviagation'
import HeaderTitle from '../../components/inner/HeaderTitle'
import Title from '../../components/header/HeaderTitle'
import Footer from '../../components/container/Footer'

import AboutContainer from '../../components/container/company/AboutContainer'
import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderContent from '../../components/header/HeaderContent'
import COLOR from '../../global/Color'
import styled from 'styled-components'

import BackgroundImage from '../../assets/contactBackgroundImage.png'
import Adress from '../../assets/main/ContactAdressIcon.png'
import Phone from '../../assets/main/ContactPhoneIcon.png'
import Fax from '../../assets/main/ContactFaxIcon.png'
import ContactInnerItem from '../../components/inner/contact/ContactInnerItem'

const Contact = () => {

  const EmailSend = ({ email, subject, children }) => {
    return (
      <a href={`mailto:${email}?subject=${encodeURIComponent(subject)})`} style={{textDecoration: 'none'}}>
        {children}
      </a>
    )
  }

  return (
    <DefaultContainer>
      <Header headerBackgroundImage={BackgroundImage}>
        <MainNaviagation />
        <HeaderTitle>Contact</HeaderTitle>
      </Header>

      <>
        <HeaderContainer>
          <Title>Contact</Title>
          <HeaderContent>궁금한 사항이 있다면 언제라도{' '}
            <EmailSend email={'support@eplus.co.kr'} subject={'문의드립니다.'}>
              <span style={{color: COLOR.MAGORELLEBLUE, fontWeight: 700, textDecoration: 'underline'}}>Eplus</span>
            </EmailSend>
          에게 문의주세요. </HeaderContent>
          
          <MapContainer>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5626.839313972437!2d127.09566859216243!3d37.40606866606508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca78a348106a5%3A0x2af237b33940ee6e!2zKOyjvCnsnbTtlITrn6zsiqQ!5e0!3m2!1sko!2skr!4v1718682401408!5m2!1sko!2skr" loading="lazy" style={{width: '100%', height: '100%'}} />
          </MapContainer>
          
          <InfoContainer>
            <ContactInnerItem Icon={Adress} title={'주소'}>경기도 성남시 분당구 판교로 255번길 9-22 우림 W-City 708호</ContactInnerItem>
            <ContactInnerItem Icon={Phone} title={'전화'}>031-628-8600</ContactInnerItem>
            <ContactInnerItem Icon={Fax} title={'팩스'}>031-628-8601</ContactInnerItem>
          </InfoContainer>
        </HeaderContainer>
      </>

      <Footer />
    </DefaultContainer >
  )
}

const MapContainer = styled.div`
  width: 73vw;
  height: 60vh;
  
  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
    height: 50vh;
  }
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    height: 40vh;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    height: 30vh;
  }
`

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ccc;

  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
    flex-direction: column;
  }
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    padding-top: 5%;
    flex-direction: column;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    padding-top: 5%;
    flex-direction: column;
    font-size: small;
  }
`

export default Contact