import React from 'react'
import styled from 'styled-components';
import pdfImage from '../../../assets/pdfImage.png'

import OG01A1B from '../../../assets/PDF/OG01A1B.pdf'
import OG02B1B from '../../../assets/PDF/OG02B1B.pdf'
import OH08A from '../../../assets/PDF/OH08A.pdf'
import OS12D40 from '../../../assets/PDF/OS12D40.pdf'
import OV64B from '../../../assets/PDF/OV64B.pdf'
import OVB0BH0 from '../../../assets/PDF/OVB0BH0.pdf'
import OX03D4C from '../../../assets/PDF/OX03D4C.pdf'
import OX05B1S from '../../../assets/PDF/OX05B1S.pdf'
import OCHFA10 from '../../../assets/PDF/OCHFA10.pdf'
import OH08B from '../../../assets/PDF/OH08B.pdf'
import OX08D from '../../../assets/PDF/OX08D10.pdf'
import OV08X from '../../../assets/PDF/OV08X.pdf'
import SIZE from '../../../global/Size';
import COLOR from '../../../global/Color';

const data = [
    ["Mobile Devices", "High-Performance", "200M Pixel", "OVB0B", OVB0BH0],
    ["Mobile Devices", "Front facing selfie", "64M Pixel", "OV64B", OV64B],
    ["Automotive", "Viewing", "3Mega SoC", "OX03D4C", OX03D4C],
    ["Automotive", "ADAS", "8M Pixel", "OX08D", OX08D],
    ["Automotive", "Sensing", "5M Pixel", "OX05B", OX05B1S],
    ["Surveillance", "Surveillance System", "12M Pixel", "OS12D", OS12D40],
    ["AR/VR", "Global shutter", "2M Pixel", "OG02B", OG02B1B],
    ["Medical Imaging", "High-Performance", "8M Pixel", "OH08B", OH08B],
    ["Computing", "Video conferencing", "8M Pixel", "OV08X", OV08X],
    ["Computing", "Authentication", "RGB-Ir", "OG01A", OG01A1B]
  ];

const OmmnivisionTable = () => {
    return (
        <Table>
        <thead>
            <Tr>
            <Th>Category</Th>
            <Th>Description</Th>
            <Th>Specification</Th>
            <Th>Model</Th>
            <Th>PDF</Th>
            </Tr>
        </thead>
        <tbody>
            {data.map((row, index) => (
            <Tr key={index}>
                <Td>{row[0]}</Td>
                <Td>{row[1]}</Td>
                <Td>{row[2]}</Td>
                <Td>{row[3]}</Td>
                <Td>
                    {
                        row[4] !== null ?
                        <PdfIcon src={pdfImage} alt="PDF" onClick={() => window.open(row[4], '_blank')} /> : 
                        <div style={{fontSize: SIZE.XLARGE, textAlign: 'center', color: COLOR.DARKGRAY}}>-</div>
                    }
                </Td>
            </Tr>
            ))}
        </tbody>
        </Table>
      );
}

const Table = styled.table`
  width: 73%;
  border-collapse: collapse;
  margin-top: 5%;
  margin-bottom: 3%;


  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }

`;

const Th = styled.th`
  border: 1px solid #ddd;
  border-top: 1px solid #643FDA;
  background-color: #f2f2f2;
  padding: 15px;
`;

const Td = styled.td`
  width: 10%;
  padding: 15px;
  border: 1px solid #ddd;
  text-align: center;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  &:hover {
    background-color: #ddd;
  }
`;

const PdfIcon = styled.img`
  width: 20%;
  height: 20%;
  cursor: pointer;
  object-fit: contain;
  transform: scale(0.7);
`;

export default OmmnivisionTable