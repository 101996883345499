import React from "react";
import styled from "styled-components";

const IKOSolutions = ({ Item }) => {
  return (
    <Container>
      {Item.map((item) => (
        <ImageItem src={item} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding-bottom: 3%;

  /* 800px 이상 1200px 미만 */
  @media (min-width: 800px) and (max-width: 1199px) {
    grid-template-columns: repeat(4, 1fr);
  }

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    grid-template-columns: repeat(2, 1fr);
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ImageItem = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

export default IKOSolutions;
