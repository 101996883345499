import React from "react";
import styled from "styled-components";

const IKOIntro = ({ Icon, Content }) => {
  return (
    <Container>
        <IconImage src={Icon}/>
        <ContentText>{Content}</ContentText>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: rows;
  justify-content: center;
  align-items: center;
`;

const IconImage = styled.img`
  width: 30%;
  height: 40%;
  object-fit: contain;
  padding: 10px;
  border: 1px solid #ccc;
`;

const ContentText = styled.p`
    width: 70%;
    font-size: 17px;
    padding-left: 5%

`

export default IKOIntro;
