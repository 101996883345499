import React from 'react'
import styled from 'styled-components';

const ThreeImagePanel = ({children, Image01, Image02, Image03}) => {
  return (
    <OuterContainer>
      <ImageRow>
        <StyledImage src={Image01} />
        <StyledImage src={Image02} />
        <StyledImage src={Image03} />
      </ImageRow>
      <ContentContainer>
        {children}
      </ContentContainer>
    </OuterContainer>
  );
}
  
  const OuterContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 60px;

      /* 450px 이상 800px 미만 */
      @media (min-width: 450px) and (max-width: 799px) {
        flex-direction: column;
      }
      
      /* 450px 미만 */
      @media (max-width: 449px) {
        flex-direction: column;
      }
  `;
  
  const ImageRow = styled.div`
    width: 60%;
    display: flex;
    gap: 10px;

      /* 450px 이상 800px 미만 */
      @media (min-width: 450px) and (max-width: 799px) {
        width: 100%;
      }
      
      /* 450px 미만 */
      @media (max-width: 449px) {
        width: 100%;
      }
  `;
  
  const StyledImage = styled.img`
    width: 32%;
    object-fit: contain;
  `;
  
  const ContentContainer = styled.div`
    width: 40%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    
    /* 450px 이상 800px 미만 */
    @media (min-width: 450px) and (max-width: 799px) {
        padding-left: 0px;
        width: 100%;
      }
      
      /* 450px 미만 */
      @media (max-width: 449px) {
        padding-left: 0px;
        width: 100%;
      }
  `;
  

export default ThreeImagePanel