import React from "react";
import styled from "styled-components";

const QCTpartnerTwoImage = ({ Image01, Image02 }) => {
  return (
    <Container>
      <PartnerImage src={Image01} />
      <PartnerImage src={Image02} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const PartnerImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  padding-bottom: 50px
`;

export default QCTpartnerTwoImage;
