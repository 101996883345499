import React from 'react'
import SIZE from '../../global/Size'
import COLOR from '../../global/Color'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import SubHeading from '../../components/inner/venderProduct/SubHeading'
import ImageTextSmall from '../../components/inner/technologies/ImageTextSmall'
import TwoImage from '../../components/inner/technologies/TwoImage'
import TwoTitleWithImage from '../../components/inner/technologies/TwoTitleWithImage'

import DemsImage01 from '../../assets/technologies/demsImage01.png'
import DemsImage02 from '../../assets/technologies/demsImage02.png'
import DemsImage03 from '../../assets/technologies/demsImage03.png'
import DemsImage04 from '../../assets/technologies/demsImage04.png'
import DemsImage05 from '../../assets/technologies/demsImage05.png'
import DemsImage06 from '../../assets/technologies/demsImage06.png'
import DemsImage07 from '../../assets/technologies/demsImage07.png'
import DemsImage08 from '../../assets/technologies/demsImage08.png'
import DemsImage09 from '../../assets/technologies/demsImage09.png'
import DemsImage10 from '../../assets/technologies/demsImage10.png'
import DemsImage11 from '../../assets/technologies/demsImage11.png'
import styled from 'styled-components'

const Dems = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderTitle>DEMS</HeaderTitle>
        <div style={{width: '100%', display: 'flex', gap: 10, paddingTop: 60, paddingBottom: 60, justifyContent: 'space-between'}}>
          <DrivingLeftSideImageView>
            <img style={{width: '70%', objectFit: 'contain'}} src={DemsImage01}/>
          </DrivingLeftSideImageView>

          <DrivingRigthSideContentView>
            <p style={{fontSize: SIZE.LARGE, color: COLOR.MAGORELLEBLUE, fontWeight: 600}}>Driving State Monitoring Alarming System</p>
            <span style={{lineHeight: 1.7}}>
              졸음방지시스템은 차 내부에 장착한 적외선 카메라로 운전자의 눈동자 움직임과 안면 근육의 변화를 통해 운전자의 상태를 파악한다. <br></br>
              이 때 운전자의 눈 깜박임과 얼굴 방향 패턴을 측정해 정상 상태가 아닐 때는 경고음을 울리고 특정 위치에 강한 진동을 줌으로써 졸음운전을 하는 운전자에게 <br></br>
              사고 위험 경고를 알리는 장치이다.
            </span>
          </DrivingRigthSideContentView>
        </div>

        <div style={{fontSize: SIZE.X05LARGE, fontWeight: 600 }}>Stats Caused by Drowsy Driving</div>
        <div style={{width: '100%', display: 'flex', gap: 10, paddingBottom: 40, justifyContent: 'space-between'}}>
          <LeftSideImageView>
            <img style={{width: '50%', objectFit: 'contain'}} src={DemsImage02}/>
            <img style={{width: '50%', objectFit: 'contain'}} src={DemsImage03}/>
          </LeftSideImageView>
          <RigthSideContentView>
            <p style={{fontSize: SIZE.LARGE, color: COLOR.MAGORELLEBLUE, fontWeight: 600}}>Driving State Monitoring Alarming System</p>
            <span style={{lineHeight: 2.2}}>
            <span style={{fontWeight: 700, color: COLOR.MAGORELLEBLUE}}>6%</span> OF ALL VEHICLE CRASHES INVOLVED A DROWSY DRIVER. <br></br>
            <span style={{fontWeight: 700, color: COLOR.MAGORELLEBLUE}}>7%</span> OF THESE CRASHES LED TO VICTIMS REQUIRING MEDICAL<br></br>
            <span style={{fontWeight: 700, color: COLOR.MAGORELLEBLUE}}>13%</span> OF ALL CRASHES REQUIRED ONE OF MORE PERSONS TO BE<br></br>
            <span style={{fontWeight: 700, color: COLOR.MAGORELLEBLUE}}>21%</span> OF ALL FATAL CRASHES ARE BELIEVED TO INVOLVE A DROWSY DRIVER<br></br>
            IT IS ESTIMATED THAT <span style={{fontWeight: 700, color: COLOR.MAGORELLEBLUE}}>109,000</span> OFTHESE ACCIDENTS LED TO INJURIES<br></br>
            IT IS ESTIMATED THAT <span style={{fontWeight: 700, color: COLOR.MAGORELLEBLUE}}>328,000</span> CRASHES YEARLY ARE THE RESULT OF DROWSY DRIVING<br></br>
            IT IS ESTIMATED THAT THERE WERE <span style={{fontWeight: 700, color: COLOR.MAGORELLEBLUE}}>6,400</span> FATAL CRASHES RELATED TO DROWSY DRIVING
            </span>
            <p style={{color: COLOR.DARKGRAY}}>Source : November 2014 study from the AAA Foundation for Public Safety</p>
          </RigthSideContentView>
        </div>

        <SubHeading>Mounted Photos</SubHeading>

        <MountedPhotosContainer>
          <ImageTextSmall Image={DemsImage04} Text={'Above the steering column cover (Recommended)'}/>
          <ImageTextSmall Image={DemsImage05} Text={'At the front bottom of the instrument panel'}/>
          <ImageTextSmall Image={DemsImage06} Text={'At the front top of the instrument panel'}/>
          <ImageTextSmall Image={DemsImage07} Text={'Above the dashboard'}/>
        </MountedPhotosContainer>

        <SubHeading>Names/Functions of Each Part</SubHeading>
        <TwoImage Image01={DemsImage08} Image02={DemsImage09}/>

        <HeaderContentSmall>
          <NamesFunctionsContainer>
            <span style={{fontWeight: 600, fontSize: SIZE.MEDIUM2, color: COLOR.MAGORELLEBLUE, paddingRight: 10}}>Operation status LED : </span>
            <span >for display of such statuses as the driver’s drowsiness, negligence in keeping eyes forward, <br></br>
              and movement of the face beyond the detectable range <span style={{color: COLOR.DARKGRAY}}>(in green/blue/red)</span> </span>
          </NamesFunctionsContainer>

          <NamesFunctionsContainer>
            <span style={{fontWeight: 600, fontSize: SIZE.MEDIUM2, color: COLOR.MAGORELLEBLUE, paddingRight: 10}}>
              Camera lens : 
            </span>
            <span >
              for monitoring of the driver's driving condition in real time
            </span>
          </NamesFunctionsContainer>

          <NamesFunctionsContainer>
            <span style={{fontWeight: 600, fontSize: SIZE.MEDIUM2, color: COLOR.MAGORELLEBLUE, paddingRight: 10}}>
              GPS Status LED :
            </span>
            <span style={{lineHeight: 2}}>
              for display of the status of GPS signals <span style={{color: COLOR.DARKGRAY}}>(only when the optional GPS cable is connected)</span> <br></br>

              · White LED ON being ready to log on with GPS signal <br></br>
              · White LED blinking : waiting for log on, but GPS connected <br></br>
              · White LED OFF : no GPS signal
            </span>
          </NamesFunctionsContainer>

          <NamesFunctionsContainer>
            <span style={{fontWeight: 600, fontSize: SIZE.MEDIUM2, color: COLOR.MAGORELLEBLUE, paddingRight: 10}}>
              Infrared LED :
            </span>
            <span >
              for detecting the driver's face at the dark nights and the tunnels with the LED lights
            </span>
          </NamesFunctionsContainer>

          <NamesFunctionsContainer>
            <span style={{fontWeight: 600, fontSize: SIZE.MEDIUM2, color: COLOR.MAGORELLEBLUE, paddingRight: 10}}>
              Function switch :
            </span>
            <span >
              for control of the volume and the sensitivity <br></br>
              · A short press is for control of the volume <span style={{color: COLOR.DARKGRAY}}>(Mute/1~4 step)</span> <br></br>
              · A long press is for control of the sensitivity <span style={{color: COLOR.DARKGRAY}}>(High: Level 3; Middle: Level 2; Low: Level 1)</span>
            </span>
          </NamesFunctionsContainer>

          <NamesFunctionsContainer>
            <span style={{fontWeight: 600, fontSize: SIZE.MEDIUM2, color: COLOR.MAGORELLEBLUE, paddingRight: 10}}>
              USB Port :
            </span>
            <span >
              for connection of various USB accessories <span style={{color: COLOR.DARKGRAY}}>(incl. LED warning light/vibrator)</span> and for update of F/W
            </span>
          </NamesFunctionsContainer>

          <NamesFunctionsContainer>
            <span style={{fontWeight: 600, fontSize: SIZE.MEDIUM2, color: COLOR.MAGORELLEBLUE, paddingRight: 10}}>
              I/O Port :
            </span>
            <span >
              for interface with an external GPS and other external devices <span style={{color: COLOR.DARKGRAY}}>(car DVRs and navigators, etc.)</span>
            </span>
          </NamesFunctionsContainer>

          <NamesFunctionsContainer>
            <span style={{fontWeight: 600, fontSize: SIZE.MEDIUM2, color: COLOR.MAGORELLEBLUE, paddingRight: 10}}>
              Power socket :
            </span>
            <span >
            for connection to the cigarette power cable <span style={{color: COLOR.DARKGRAY}}>(DC12/24V)</span>
            </span>
          </NamesFunctionsContainer>

          <TwoTitleWithImage
            Image01={DemsImage10}
            Title01={'Recommended Mounts'}
            Image02={DemsImage11}
            Title02={'Detectable Ranges'}/>
        </HeaderContentSmall>
      </HeaderContainer>
    </>
  )
}

const DrivingLeftSideImageView = styled.div`
  display: flex;
  width: 26%;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`

const DrivingRigthSideContentView = styled.div`
  width: 74%;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    width: 100%;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    width: 100%;
  }
`

const LeftSideImageView = styled.div`
  padding-top: 2%;
  display: flex;
  width: 50%;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`

const RigthSideContentView = styled.div`
  width: 54%;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    width: 100%;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    width: 100%;
  }
`

const MountedPhotosContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 40px;
  gap: 20px;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    flex-direction: column;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    flex-direction: column;
  }
`

const NamesFunctionsContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    flex-direction: column;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    flex-direction: column;
  }
`

export default Dems