import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import DefaultContainer from "../../components/container/DefaultContainer";
import Header from "../../components/container/Header";
import MainNaviagation from "../../components/navigation/MainNaviagation";
import InnerNaviagation from "../../components/navigation/InnerNaviagation";
import Footer from "../../components/container/Footer";
import AboutContainer from "../../components/container/company/AboutContainer";
import HeaderTitle from "../../components/inner/HeaderTitle";

import backgroundImage from "../../assets/vendorProduct/VendorBackgroundImage.png";

import Goodix from "./Goodix";
import Omnivision from "./Omnivision";
import SierraWireless from "./DeletePage/SierraWireless";
import Syncomm from "./Syncomm";
import Byd from "./DeletePage/Byd";
import Catl from "./DeletePage/Catl";
import ScrollToTop from "../../api/ScrollToTop";
import Quectel from "./Quectel";
import Ikotek from "./Ikotek";

const Vendor = () => {
  const CompanyItem = ["Omnivision", "Goodix", "Syncomm", "Quectel", "Ikotek"];

  return (
    <DefaultContainer>
      <Header headerBackgroundImage={backgroundImage}>
        <MainNaviagation />
        <HeaderTitle>Vendor & Product</HeaderTitle>
        <InnerNaviagation item={CompanyItem} />
      </Header>

      <AboutContainer>
        <Routes>
          <Route
            path="/"
            element={<Navigate to="omnivision" replace={true} />}
          />
          <Route path="/omnivision" element={<Omnivision />} />
          <Route path="/goodix" element={<Goodix />} />
          <Route path="/syncomm" element={<Syncomm />} />
          <Route path="/quectel" element={<Quectel />} />
          <Route path="/ikotek" element={<Ikotek />} />
          {/* <Route path='/sierra wireless' element={<SierraWireless /> }/> */}
          {/* <Route path='/byd' element={<Byd /> }/> */}
          {/* <Route path='/catl' element={<Catl /> }/> */}
        </Routes>
      </AboutContainer>

      <Footer />
    </DefaultContainer>
  );
};

export default Vendor;
