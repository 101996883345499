import React from 'react'
import styled from 'styled-components';

const TwoImageBox = ({ Image01, Image02 }) => {
  return (
    <Container>
      <StyledImage src={Image01} />
      <StyledImage src={Image02} />
    </Container>
  );
  }
  
  const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 50px;

      /* 450px 이상 800px 미만 */
      @media (min-width: 450px) and (max-width: 1300px) {
        flex-direction: column;
      }
      
      /* 450px 미만 */
      @media (max-width: 449px) {
        flex-direction: column;
      }
      `;
  
  const StyledImage = styled.img`
    width: 48%;

    /* 450px 이상 800px 미만 */
    @media (min-width: 450px) and (max-width: 1300px) {
      width: 100%;
    }
    
    /* 450px 미만 */
    @media (max-width: 449px) {
      width: 100%;
    }
  `;

export default TwoImageBox